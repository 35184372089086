<template>
  <div class="home-how-to-buy">
    <div class="wrap cover">
      <div class="maru">
        <h2>業界最安値</h2>
      </div>
      <p>
        バイオレタリーは業界最安値に挑戦していますので、すでに取得済の他社の見積書があれば、同スペックならばその価格よりも安く製品を提供できるように努力させていただきます。まずはお気軽に問い合わせくださいませ。また、ボリュームディスカウントもご相談を承ります。
      </p>
    </div>
    <img
      alt="バイオくんオレンジ"
      src="@/assets/bioletry-top-ira005.png"
      class="ora"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeHowToBuy",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-how-to-buy {
  background-color: var(--sub);
  color: white;
  padding: 50px 0;
  position: relative;
  @media screen and (max-width: 820px){
    padding: 80px 0;
  }
  .cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 820px) {
      flex-direction: column;
    }
    .maru {
      max-width: 20%;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: var(--sub2);
      text-align: center;
      display: inline-grid;
      align-content: center;
      @media screen and (max-width: 820px) {
        max-width: 100%;
        margin: 0 auto;
        border-radius: 20px;
        height: auto;
        padding: 20px 0;
      }
      h2{
        font-size: 32px;
        @media screen and (max-width: 820px){
          font-size: 26px;
        }
      }
    }
    p {
      width: 75%;
      padding: 20px 0;
      @media screen and (max-width: 820px) {
        width: 100%;
      }
    }
  }
  .ora {
    width: 150px;
    position: absolute;
    bottom: -17%;
    right: 22%;
    @media screen and (max-width: 767px) {
      width: 100px;
      bottom: -8%;
      right: 10%;
    }
  }
}
</style>
