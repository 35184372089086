<template>
  <div class="layout-footer">
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
        <div class="copyright">
          <p>Copyright © TK Eco Systems bioletary All Rights Reserved.</p>
        </div>
      </div>

      <div class="sp-contact-overlay">
        <div class="contact-item">
          <router-link to="/" v-scroll-to="`#contact`" class="req-box">
            <span>資料請求・お問い合わせ</span>
          </router-link>

          <!-- <router-link to="/" v-scroll-to="`#contact`" class="mail-box">
            <span>お問い合わせ</span>
          </router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--sub2);
}
.st1 {
  fill: none;
}

.footer {
  .copyright {
    font-size: 13px;
    color: white;
    background-color: var(--sub);
    text-align: right;
    padding: 150px 100px 20px 0;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      padding: 10px 0 60px;
      // margin-top: 15px;
      text-align: right;
      padding-right: 10px;
    }
  }
  .sp-contact-overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      position: fixed;
      bottom: 0;
      width: 100%;
      // height: 65px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 999;
      .contact-item {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        justify-content: center;
      }
      a {
        text-decoration: none;
      }
      .req-box,
      .mail-box {
        display: flex;
        position: relative;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 95%;
        margin-bottom: 5px;
        padding: 10px;
        color: white;
        border: 1px solid white;
        span {
        color: white;
          font-size: 15px;
        }
      }
      .req-box {
        background-color: var(--sub);
      }
      .mail-box {
        background-color: var(--sub2);
      }
    }
  }
}
.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 54px;
  }
}
</style>
