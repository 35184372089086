<template>
  <div class="home-mechanism">
    <div class="wrap space-top-bottom-set-250">
      <div class="top-cover">
        <div class="left">
          <h2>バイオトイレとは？<br />バイオトイレの仕組み</h2>
          <p>
            バイオトイレは、人間の排泄物やトイレットペーパーを、自然界に生息するバイオ菌（バクテリア・微生物）の働きによって分解・処理し、消滅させるトイレのことです。そのため、従来の仮設式トイレや簡易トイレにある「臭い」「汲み取りや後処理」「水」が不要です。環境へのやさしから、近年はSDGsとしても注目を集めています。
          </p>
        </div>
        <img
          alt="バイオくん"
          src="@/assets/bioletry-top-ira002.png"
          class="pc-only"
        />
      </div>
      <div class="bottom-cover">
        <div class="sp-cover">
          <p class="sub-title">
            実際にバイオトイレが<br class="sp-only" />排泄物を処理する<br
              class="sp-only"
            />仕組み
          </p>
          <img
            alt="バイオくん"
            src="@/assets/bioletry-top-ira002sp.png"
            class="sp-only"
          />
        </div>
        <div class="img-cover">
          <img
            alt="仕組み01"
            src="@/assets/bioletry-top-008.png"
            class="pc-only"
          />
          <img
            alt="仕組み01"
            src="@/assets/bioletry-top-008sp.png"
            class="sp-only"
          />
          <img
            alt="仕組み02"
            src="@/assets/bioletry-top-009.png"
            class="pc-only"
          />
          <img
            alt="仕組み02"
            src="@/assets/bioletry-top-009sp.png"
            class="sp-only"
          />
          <img
            alt="仕組み03"
            src="@/assets/bioletry-top-010.png"
            class="pc-only"
          />
          <img
            alt="仕組み03"
            src="@/assets/bioletry-top-010sp.png"
            class="sp-only"
          />
          <img
            alt="仕組み04"
            src="@/assets/bioletry-top-011.png"
            class="pc-only"
          />
          <img
            alt="仕組み04"
            src="@/assets/bioletry-top-011sp.png"
            class="sp-only"
          />
        </div>
        <p>
          この仕組みで、トイレットペーパーを含めた排泄物を分解することで、臭わず、汲み取りや後処理も不要で、水も必要としないのがバイオトイレです。当社は、数あるバイオ菌（バクテリア・微生物）の中でも、排泄物を分解する能力に優れたものを使用しています。当社の製品は、塩分に強く脂分にも強いなどの特徴も持ち、さらにその他にも独自の技術を用いています。
        </p>
        <p style="margin-top:20px;">バイオ菌とは・・バイオレタリー独自の排泄物の分解に優れたバクテリア・微生物の総称</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeMechanism",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-mechanism {
  background-color: var(--sub);
  color: white;
  position: relative;

  .top-cover {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .left {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h2{
        margin-bottom: 20px;
      }
    }
    img {
      width: 25%;
    }
  }
  .bottom-cover {
    .sub-title {
      font-size: 25px;
      margin-bottom: 30px;
      // color: #e5f5fb;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .img-cover {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      img {
        width: 23%;
        @media screen and (max-width: 767px){
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.sp-cover {
  @media screen and (max-width: 767px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    img {
      width: 37%;
    }
    .sub-title {
      font-size: 16px;
    }
  }
}
</style>
