<template>
  <div class="katei">
    <div>
      <KeitaiTop />
    </div>
    <div class="space-top-200 kinkun">
      <UiHeading
        :h="'3'"
        :type="'E'"
        :text="'製品一覧'"
        class="space-middle-bottom-img"
      />
      <KeitaiList class="backcolor" />
            <img
        alt="菌くんたち"
        src="@/assets/bioletry-kyotu-ira006.png"
        class="kinkun-img"
      />
      <KeitaiList2 class="backcolor2" />
    </div>
    <div class="space-top-bottom-set wrap bt-0-sp">
      <KeitaiExplanation />
    </div>

    <div class="backcolor">
      <HomeRequInfo class="mb-50" />
    </div>

    <div class="space-top-bottom-set wrap bt-0">
      <KeitaiExplanation2 />
    </div>

    <!-- <div class="backcolor">
      <HomeRequInfo class="mb-150" />
    </div> -->

    <div class="space-top-bottom-set-250-200 wrap">
      <HomeMerit />
    </div>
    <div class="space-top-bottom-set wrap pt-50">
      <HomeHowToUse />
    </div>
    <div class="space-top-bottom-set wrap">
      <KeitaiEtc />
    </div>
    <div class="space-top-bottom-set wrap">
      <KeitaiQA />
    </div>
    <div class="space-top-bottom-set wrap">
      <KeitaiFlow />
    </div>
    <div class="space-top-80 space-top-70 space-bottom">
      <HomeContact id="contact" />
    </div>
    <img
      alt="背景付バイオくん"
      src="@/assets/bioletry-top-ira007.png"
      class="last pc-only"
    />
    <img
      alt="背景付バイオくん"
      src="@/assets/bioletry-top-ira007sp.png"
      class="last sp-only"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KeitaiTop from "@/components/Keitai/Top.vue";
import KeitaiList from "@/components/Keitai/List.vue";
import KeitaiList2 from "@/components/Keitai/List2.vue";
import KeitaiExplanation from "@/components/Keitai/Explanation.vue";
import KeitaiExplanation2 from "@/components/Keitai/Explanation2.vue";
import HomeRequInfo from "@/components/Home/RequInfo.vue";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeHowToUse from "@/components/Home/HowToUse.vue";
import HomeMerit from "@/components/Home/Merit.vue";
import KeitaiEtc from "@/components/Keitai/Etc.vue";
import HomeContact from "@/components/Home/Contact.vue";
import KeitaiFlow from "@/components/Keitai/Flow.vue";
import KeitaiQA from "@/components/Keitai/QA.vue";

export default {
  name: "Keitai",
  components: {
    KeitaiTop,
    UiHeading,
    KeitaiList,
    KeitaiList2,
    KeitaiExplanation,
    KeitaiExplanation2,
    HomeRequInfo,
    HomeHowToUse,
    HomeMerit,
    KeitaiEtc,
    HomeContact,
    KeitaiFlow,
    KeitaiQA,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.backcolor {
  background-color: var(--sub2);
}
.backcolor2 {
  background-color: var(--sub);
}
.mb-150 {
  margin-bottom: 150px;
}
.last {
  vertical-align: bottom;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: 767px) {
    width: 80%;
    margin: 0 auto;
  }
}
.bt-0-sp {
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
}
.bt-0 {
  padding-bottom: 0;
}
.pt-50 {
  padding-top: 50px;
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
}
.mb-50 {
  margin-bottom: 100px;
  @media screen and(max-width: 767px) {
    margin-bottom: 50px;
  }
}
.kinkun {
  position: relative;
}
.kinkun-img {
  position: absolute;
  width: 200px;
  top: 9.8%;
  right: 10%;
    @media screen and (min-width:768px) and ( max-width:1024px){
    top: 12%;
  }
  @media screen and(max-width: 767px) {
    top: 5.6%;
    right: 20px;
  }
}
</style>
