<template>
  <div class="okugai">
    <div>
      <OkugaiTop />
    </div>
    <div class="space-top-200 kinkun">
      <UiHeading
        :h="'3'"
        :type="'E'"
        :text="'製品一覧'"
        class="space-middle-bottom-img"
      />
      <OkugaiList class="backcolor" />
            <img
          alt="菌くんたち"
          src="@/assets/bioletry-kyotu-ira006.png"
          class="kinkun-img"
        />
    </div>
      <OkugaiList3 class="backcolor2 space-bottom-200m" />
    <div class="space-bottom wrap">
      <OkugaiExplanation />
    </div>

    <div class="backcolor2">
      <HomeRequInfo class="mb-150" />
    </div>

    <OkugaiList2 class="backcolor" />
    <OkugaiList4 class="backcolor2" />

    <div class="space-top-bottom-set wrap bt-0-sp">
      <OkugaiExplanation2 />
    </div>

    <div class="backcolor">
      <HomeRequInfo />
    </div>

    <div class="space-top-bottom-set-250-200 wrap">
      <HomeMerit />
    </div>
    <div class="mb-150 wrap">
      <HomeHowToUse />
    </div>
    <div class="space-top-bottom-set wrap bt-20-sp">
      <OkugaiEtc />
    </div>
    <div class="space-top-bottom-set wrap">
      <OkugaiQA />
    </div>
    <div class="space-top-bottom-set wrap">
      <OkugaiFlow />
    </div>
    <div class="space-top-80 space-top-70 space-bottom">
      <HomeContact id="contact" />
    </div>
    <img
      alt="背景付バイオくん"
      src="@/assets/bioletry-top-ira007.png"
      class="last pc-only"
    />
    <img
      alt="背景付バイオくん"
      src="@/assets/bioletry-top-ira007sp.png"
      class="last sp-only"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OkugaiTop from "@/components/Okugai/Top.vue";
import OkugaiList from "@/components/Okugai/List.vue";
import OkugaiList2 from "@/components/Okugai/List2.vue";
import OkugaiList3 from "@/components/Okugai/List3.vue";
import OkugaiList4 from "@/components/Okugai/List4.vue";
import OkugaiExplanation from "@/components/Okugai/Explanation.vue";
import OkugaiExplanation2 from "@/components/Okugai/Explanation2.vue";
import HomeRequInfo from "@/components/Home/RequInfo.vue";
import UiHeading from "@/components/Ui/Heading.vue";
import HomeHowToUse from "@/components/Home/HowToUse.vue";
import HomeMerit from "@/components/Home/Merit.vue";
import OkugaiEtc from "@/components/Okugai/Etc.vue";
import HomeContact from "@/components/Home/Contact.vue";
import OkugaiFlow from "@/components/Okugai/Flow.vue";
import OkugaiQA from "@/components/Okugai/QA.vue";

export default {
  name: "Okugai",
  components: {
    OkugaiTop,
    UiHeading,
    OkugaiList,
    OkugaiList2,
    OkugaiList3,
    OkugaiList4,
    OkugaiExplanation,
    OkugaiExplanation2,
    HomeRequInfo,
    HomeHowToUse,
    HomeMerit,
    OkugaiEtc,
    HomeContact,
    OkugaiFlow,
    OkugaiQA,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.backcolor {
  background-color: var(--sub2);
}
.backcolor2 {
  background-color: var(--sub);
}
.mb-150 {
  margin-bottom: 150px;
}
.last {
  vertical-align: bottom;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: 767px) {
    width: 80%;
    margin: 0 auto;
  }
}

.bt-0-sp {
  @media screen and(max-width: 767px) {
    padding: 0 20px;
  }
}
.bt-20-sp{
  padding-bottom: 50px;
  @media screen and(max-width: 767px) {
    padding: 20px;
  }
}
.kinkun{
  position: relative;
}
.kinkun-img{
  position: absolute;
  width: 200px;
  top: 15.5%;
  right: 10%;
  @media screen and (min-width:768px) and ( max-width:1024px){
    top: 18.3%;
  }
    @media screen and(max-width: 767px){
  top: 9.3%;
  right: 20px;
  }
}
</style>
