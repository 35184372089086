<template>
  <div class="company">
    <div>
      <CompanyTop />
    </div>
        <div class="space-top-80 space-top-70 space-bottom">
      <HomeContact/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyTop from "@/components/Company/Top.vue";
import HomeContact from "@/components/Home/Contact.vue";
// import OkugaiFlow from "@/components/Okugai/Flow.vue";
// import OkugaiQA from "@/components/Okugai/QA.vue";

export default {
  name: "Okugai",
  components: {
    CompanyTop,
    // HomeRequInfo,
    // HomeHowToUse,
    // HomeMerit,
    HomeContact,
    // OkugaiFlow,
    // OkugaiQA,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.backcolor {
  background-color: var(--sub2);
}
.backcolor2 {
  background-color: var(--sub);
}
.mb-150 {
  margin-bottom: 150px;
}
.last {
  vertical-align: bottom;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: 767px) {
    width: 80%;
    margin: 0 auto;
  }
}

.bt-0-sp {
  @media screen and(max-width: 767px) {
    padding: 0 20px;
  }
}
</style>
