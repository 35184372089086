var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-button",attrs:{"ontouchstart":""}},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: '#app',
      container: 'body',
      duration: 750,
      easing: 'ease',
      offset: -80,
    }),expression:"{\n      el: '#app',\n      container: 'body',\n      duration: 750,\n      easing: 'ease',\n      offset: -80,\n    }"}],staticClass:"to-top-button",class:{ 'over-baseline': _vm.scrollY >= 500 }},[_c('img',{staticClass:"top",attrs:{"src":require("@/assets/to-top.png")}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }