<template>
  <div class="home">
    <div>
      <HomeTop />
    </div>
    <div>
      <HomeAbout />
    </div>
    <div class="space-top-bottom-set-250-200 wrap">
      <HomeList />
    </div>
    <div class="space-top-bottom-set-200 backcolor">
      <HomeListEasy />
    </div>
    <div class="space-top-bottom-set-200-250 wrap">
      <HomeListPortable />
    </div>
    <div class="backcolor2">
      <HomeRequInfo />
    </div>
    <div class="space-top-bottom-set-250-200 backcolor3">
      <HomeReason />
    </div>
    <div>
      <HomeMechanism />
    </div>
    <div class="space-top-bottom-set-250-200 wrap">
      <HomeMerit />
    </div>
    <div class="space-top-bottom-set wrap">
      <HomeHowToUse />
    </div>
    <div class="space-top-bottom-set">
      <HomeHowToBuy />
    </div>
    <div class="space-top-bottom-set wrap">
      <HomeQA />
    </div>
    <div class="space-top-bottom-set wrap">
      <HomeFlow />
    </div>
    <div class="space-top-80 space-top-70 space-bottom">
      <HomeContact id="contact" />
    </div>
    <img
      alt="背景付バイオくん"
      src="@/assets/bioletry-top-ira007.png"
      class="last pc-only"
    />
    <img
      alt="背景付バイオくん"
      src="@/assets/bioletry-top-ira007sp.png"
      class="last sp-only"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeHowToUse from "@/components/Home/HowToUse.vue";
import HomeHowToBuy from "@/components/Home/HowToBuy.vue";
import HomeContact from "@/components/Home/Contact.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import HomeQA from "@/components/Home/QA.vue";
import HomeMerit from "@/components/Home/Merit.vue";
import HomeAbout from "@/components/Home/About.vue";
import HomeTop from "@/components/Home/Top.vue";
import HomeList from "@/components/Home/List.vue";
import HomeListEasy from "@/components/Home/ListEasy.vue";
import HomeListPortable from "@/components/Home/ListPortable.vue";
import HomeRequInfo from "@/components/Home/RequInfo.vue";
import HomeReason from "@/components/Home/Reason.vue";
import HomeMechanism from "@/components/Home/Mechanism.vue";

export default {
  name: "Home",
  components: {
    HomeMerit,
    HomeHowToUse,
    HomeHowToBuy,
    HomeContact,
    HomeFlow,
    HomeQA,
    HomeAbout,
    HomeTop,
    HomeList,
    HomeListEasy,
    HomeListPortable,
    HomeRequInfo,
    HomeReason,
    HomeMechanism,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.backcolor {
  background-color: #e5f5fb;
}
.backcolor2 {
  background-color: var(--sub2);
}
.backcolor3 {
  background-color: #eeeeee;
}
.last {
  vertical-align: bottom;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  @media screen and (max-width: 767px) {
  width: 80%;
  margin: 0 auto;
  }
}
</style>
