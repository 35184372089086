<template>
  <div class="okugai-explanation">
    <div class="item-cover" style="padding-top:0;">
      <img
        alt="自然イメージ"
        src="@/assets/okugai/bioletry-kyotu-ira001.png"
        class="number"
      />
      <div class="text">
        <h3>床が低くて、<br />出入りがラクな設計</h3>
        <p>
          他社のバイオトイレは、床下に撹拌装置がある構造のため、床が高いです。バイオレタリーは、撹拌部のコンパクト化に成功し、床下に撹拌部がない構造のため、トイレへの出入りがラクです。
        </p>
      </div>
      <img alt="ラクな設計" src="@/assets/bioletry-okugai-14.jpg" class="zu" />
    </div>
    <div class="item-cover2">
      <img
        alt="01"
        src="@/assets/okugai/bioletry-kyotu-ira002.png"
        class="number"
      />
      <div class="text">
        <h3>自動感知・自動撹拌</h3>
        <p>
          人感センサーによって、自動点灯・自動撹拌が行われます。撹拌は、使用を始めると、「10分間撹拌し、20分停止」を3セット行います。その間に人が入れば、また1からカウントします。夜間は、PM9:00以降
          「10分間撹拌し50分停止」をAM6:00まで継続して、菌床をリフレッシュします。
        </p>
      </div>
    </div>

    <div class="item-more-cover">
      <div class="item-cover3">
        <img
          alt="03"
          src="@/assets/okugai/bioletry-kyotu-ira003.png"
          class="number"
        />
        <div class="text">
          <h3>省エネ</h3>
          <p>
            撹拌部は外周を廻すことで大トルクを発生させ、小さなモーターで稼働させることで省エネを実現しました。
          </p>
        </div>
      </div>
      <div class="flex">
      <div class="blue-back">
        <p class="title">太陽光の利用について</p>
        <p class="text">
          太陽光による発電での使用は、使用場所や使用状況によって、太陽光のみですべての電力を賄える場合とそうでない場合があります。詳細はお問い合わせくださいませ。
        </p>
      </div>
       <img
          alt="03"
          src="@/assets/okugai/bioletry-okugai-015.jpg"
          class="number"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "OkugaiExplanation",
  components: {
    // UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.okugai-explanation {
  .item-cover,
  .item-cover2,.item-cover3 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px 0 100px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 60px 0;
    }
    .number {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
      }
    }
    h3 {
      color: var(--sub);
      @media screen and(max-width: 767px){
    text-align: center;
    }
    }
  }
  .item-cover {
    .text {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .zu {
      width: 22%;
      margin-bottom: -130px;
      @media screen and (max-width: 767px) {
        width: 70%;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
    }
  }
  .item-cover2 {
    border-top: 1px var(--main) solid;
    @media screen and (max-width: 767px) {
      padding-top: 60px;
    }
    .text {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
    .item-cover3 {
    padding: 100px 0 0;
    border-top: 1px var(--main) solid;
    @media screen and(max-width: 767px) {
      padding: 60px 0 30px;
    }
  }
    .blue-back {
    margin-left: 28%;
    background-color: #e5f5fb;
    padding: 20px;
    margin-top: -30px;
    margin-bottom: 100px;
    @media screen and (min-width:768px) and ( max-width:1024px){
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      margin: 0 0 60px;
    }
    .title {
      color: var(--sub);
      font-size: 20px;
    }
  }
  .bt-0 {
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }
  }
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and(max-width: 767px){
    flex-direction: column;
  }
  img{
    width: 180px;
    margin-top: -100px;
    margin-left: 20px;
    @media screen and(max-width: 767px){
      margin-top: 0;
      margin-left: 0;
    }
  }
}
</style>
