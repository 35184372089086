<template>
  <div class="home-about">
    <div class="wrap-right space-top-bottom-set-250">
      <div class="top-cover">
        <div class="left">
          <h1>&ensp;バイオトイレ<br />「バイオレタリー」とは</h1>
          <p>
            バイオトイレ「バイオレタリー」は、これまでの仮設型トイレや簡易トイレとは全く異なり、便を分解させる働きがあるため、①臭わない、②汲み取り・後処理不要、③水がなくても使用できます。バイオトイレの技術をあらゆる用途に適用し、屋外設置型の仮設用、家庭用簡易型、携帯用折りたたみ式ダンボールの3種類の製品カテゴリをご用意しています。バイオトイレの業界最安値を目指していますので、ぜひお気軽にご相談をください。
          </p>
        </div>
        <img alt="自然イメージ" src="@/assets/bioletry-top-001.jpg" />
      </div>
      <div class="bottom-cover">
        <div class="left">
          <p>バイオレタリーはSDGsを応援しています</p>
          <img alt="sdgs" src="@/assets/bioletry-top-001.png" />
        </div>
        <div class="img-cover">
          <img
            alt="自然イメージ"
            src="@/assets/bioletry-top-002.jpg"
            class="child"
          /><img
            alt="自然イメージ"
            src="@/assets/bioletry-top-003.jpg"
            class="kind"
          />
        </div>
      </div>
    </div>
    <img
      alt="Bacterium"
      class="kin pc-only"
      src="@/assets/bioletry-top-ira001.png"
    />
    <img
      alt="Bacterium"
      class="kin sp-only"
      src="@/assets/bioletry-top-ira001sp.png"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeAbout",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-about {
  background-color: var(--sub);
  color: white;
  position: relative;
  .wrap-right {
    width: 100vw; //画面幅に対して、基本100%
    max-width: calc(1100px + calc(calc(99% - 1100px) * 0.5));
    // width: 1150px;
    margin-left: calc(calc(100vw - 1100px) * 0.5);
    line-height: 2.5em;

    @media screen and (max-width: 1100px) {
      max-width: auto;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .top-cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (max-width: 820px) {
      display: block;
    }
    .left {
      width: 50%;
      @media screen and (max-width: 820px) {
        width: 100%;
      }
      h1 {
        margin-left: -20px;
        margin-bottom: 20px;
      }
    }
    img {
      width: 40%;
      @media screen and (max-width: 820px) {
        width: calc(100% - 80px);
        margin-left: 100px;
        margin-top: 20px;
      }
    }
  }
  .bottom-cover {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 820px) {
      // flex-direction: row-reverse;
      flex-direction: column-reverse;
      margin-top: 0;
    }
    .left {
      @media screen and (max-width: 820px) {
        border: 1px solid white;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-top: 30px;
      }
      p {
        font-size: 20px;
        margin-bottom: 5px;
        letter-spacing: 0.13em;
                @media screen and (max-width: 767px) {
          letter-spacing: -0.07em;
          font-size: 17px;
        }

      }
      img {
        width: 65%;
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .img-cover {
      width: 70%;
      display: flex;
      margin-right: 100px;
      @media screen and (max-width: 820px) {
        margin-right: 20px;
        margin-left: -20px;
        width: 100%;
      }

      .child,
      .kind {
        width: 48%;
        margin-top: 10px;
      }
      .child {
        margin-right: 5%;
      }
    }
  }
  .kin {
    position: absolute;
    bottom: -6%;
    right: 5%;
    width: 30%;
    @media screen and (max-width: 767px) {
      width: 90%;
      bottom: -3%;
    }
  }
}
.space-top-bottom-set-250 {
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}
</style>
