<template>
  <div class="home-list-easy wrap">
    <div class="top-cover">
      <div class="left">
        <h3>家庭用簡易型バイオトイレ</h3>
        <p>
          臭わない、後処理不要のバイオトイレをご家庭でも使用できます。使用に際して、水道蛇口も排水溝も不要です。便を分解させる菌床の交換は原則として年に1回。従来の家庭用トイレにある、臭いや、片付けから開放されます。介護用、障害者用、備蓄・防災、キャンピングカー、キャンプ場など様々な用途で使用できます。
        </p>
      </div>
      <img alt="家アイコン" src="@/assets/bioletry-top-pa002.png" />
    </div>

    <div class="item-cover">
      <div class="item first">
        <img
          alt="バイオレタリー20"
          src="@/assets/bioletry-top-004.png"
          class="item-img"
        />
        <UiTable2 :tableList="tableList" />
        <p class="price">定価：305,000円<span>&nbsp;(税込)&emsp;※送料別</span></p>
      </div>
      <!-- <div class="item">
        <img
          alt="バイオレタリーH20"
          src="@/assets/bioletry-top-005.png"
          class="item-img"
        />
        <UiTable2 :tableList="tableList2" />
        <p class="price">定価：240,000円<span>&nbsp;(税込)&emsp;※送料別</span></p>
      </div> -->
    </div>
    <div class="link-button">
      <router-link to="/katei">家庭用簡易型バイオトイレの詳細を見る</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable2 from "@/components/Ui/Table2.vue";
export default {
  name: "HomeList",
  components: {
    UiTable2,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリー20",
        },
        {
          id: 2,
          th: "品名",
          td: "消滅型 家庭用バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W50×D65×H40",
        },
        {
          id: 4,
          th: "重量",
          td: "25kg",
        },
      ],
      // tableList2: [
      //   {
      //     id: 1,
      //     th: "型式",
      //     td: "バイオレタリーH20",
      //   },
      //   {
      //     id: 2,
      //     th: "品名",
      //     td: "消滅型 家庭用手回し型バイオトイレ",
      //   },
      //   {
      //     id: 3,
      //     th: "本体サイズ",
      //     td: "W56×D65×H70",
      //   },
      //   {
      //     id: 4,
      //     th: "重量",
      //     td: "22kg",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.top-cover {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
  .left {
    width: 80%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    h3 {
      color: var(--sub);
      margin-bottom: 20px;
      @media screen and (max-width: 767px){
        text-align: center;
      }
    }
  }
  img {
    width: 15%;
    @media screen and (max-width: 767px) {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
}
.item-cover {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .item {
    width: 48%;
    text-align: center;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .item-img {
      width: 100%;
      @media screen and (max-width: 767px) {
      margin-bottom: 10px;
      }
    }
    .price {
      // width: calc(100% - 20px);
      width: 100%;
      background-color: var(--sub);
      color: white;
      font-size: 20px;
      padding: 10px 0;
      margin: 10px 0;
      span {
        font-size: 14px;
      }
    }
  }
}
.first {
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.link-button {
  @media screen and (max-width: 767px) {
    text-align: center;
    margin-top: 0;
  }
}
</style>
