<template>
  <div class="home-reason wrap">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'バイオイレタリーが<br class=sp-only>選ばれる理由'"
      class="space-middle-bottom-text"
    />
    <div class="cover">
      <div class="left">
        <h3 class="sp-style">
          専門業者であり、<br />バイオレタリーにしかない製品
        </h3>
        <p>
          臭わない、汲み取り・後処理不要、水がなくても使用できる、というバイオトイレの特徴だけでなく、バイオレタリーには、バイオレタリーならではの特徴があります。独自の撹拌部分の構造によって、床の高さを低くすることで使いやすく、また、軽トラックにそのまま積載できるサイズになっています。また、消滅型の家庭用トイレ、携帯トイレというまだ世の中には多く出回っていない商品も開発・販売しています。お客様からのご希望・ご依頼に応じて、柔軟な商品の製造を行うことも可能です。
        </p>
      </div>
      <img src="@/assets/bioletry-top-004.jpg" />
    </div>
    <div class="cover sp-cover">
      <img src="@/assets/bioletry-top-005.jpg" />
      <div class="left">
        <h3>バイオトイレの最安値に挑戦</h3>
        <p>
          バイオトイレの製造メーカーは、日本に数えるほどしかありません。そして、その中でも、当社は、バイオトイレの専門メーカーです。専門メーカーとして、品質はもちろん、製造コストを極力抑え、お客様へ業界最安値で製品を提供することをモットーとしています。他社の見積もりを取ってから、弊社へぜひご相談ください。同スペックであれば、それよりも安い価格で提供できるように努力いたします。
        </p>
      </div>
    </div>
    <!-- <div class="cover">
      <div class="left">
        <h3>購入・レンタルが<br class="sp-only">可能です</h3>
        <p>
          屋外設置型バイオトイレについては、取引の方法として、購入・レンタルのいずれも可能です。お客様のご希望・ご都合に合わせた方法で、製品のお手配ができます。
        </p>
      </div>
      <img src="@/assets/bioletry-top-006.jpg" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "HomeHeading",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .left {
    width: 58%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    h3 {
      color: var(--sub);
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }
  img {
    width: 37%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 20px;
    }
  }
}
.sp-cover{
  @media screen and (max-width: 767px){
    flex-direction: column-reverse;
  }
}
.sp-style {
  @media screen and (max-width: 767px) {
  font-size: 25px!important;
  }
}
</style>
