<template>
  <div class="qa">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'よくある質問'"
      class="space-middle-bottom-img"
    />

    <UiQaQaBox
      v-for="qaBoxListData in qaBoxList"
      :key="qaBoxListData.id"
      :qestion="qaBoxListData.qestion"
      :answer="qaBoxListData.answer"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQaBox from "../Ui/Qa/QaBox.vue";
import UiHeading from "../Ui/Heading.vue";
export default {
  name: "about-recruit",
  components: {
    UiQaQaBox,
    UiHeading,
  },
  data() {
    return {
      qaBoxList: [
           {
          id: 1,
          qestion: "菌の補充は必要でしょうか？",
          answer:
            "週に1回、当社指定のバイオ菌（バクテリア・微生物）の補充が必要です。",
        },
        {
          id: 2,
          qestion:
            "清掃の方法を知りたいです。",
          answer:
            "霧吹きのようなもので、少量の水で中にあまり入らないようにし、洗剤を使用する場合は、天然リモネン配合の中性クリーナーの使用をお勧めします。（菌床が粘土状になると、菌が酸欠状態になります。）",
        },
        {
          id: 3,
          qestion: "便器の中に投入してはいけないものはありますか？",
          answer:
            "便とトイレットペーパー以外は投入しないでください。消臭剤や防虫剤を便器の中に入れると、バイオ菌（バクテリア・微生物）が死んでしまいます。無機物は処理できないため、金属やビニール等の有機物ではないものを入れないでください。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid var(--main);
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .left {
    width: 24%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    p {
      margin-top: 15px;
    }
    img {
      width: 80%;
      margin-top: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    h2 {
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 34px;
      }
    }
  }
  .right {
    width: 67%;
    // width: calc(100% - calc(calc(100% - 246px) * 0.5));
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.space-top-bottom-set {
  @media screen and (max-width: 767px) {
    padding-bottom: 80px;
  }
}
</style>
