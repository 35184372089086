<template>
  <div class="cotacnt-form">
    <div class="cover">
      <div class="title">
        <!-- <div v-if="pageType === 0"></div> -->
      </div>

      <div class="form" v-if="pageType === 0 || pageType === 1">
        <div class="input-cover">
          <p class="sub">法人名・屋号名<span>（※必須。ない場合は「なし」と記入）</span></p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.companyName"
              type="text"
              class="row1"
              placeholder="例）株式会社 山田工業"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.companyName }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">お名前<span>(※必須)</span></p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.name"
              type="text"
              class="row1"
              placeholder="例）山田 花子"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.name }}
          </div>
        </div>
        
        <!-- フォーム項目の変更 -->
        <div class="input-cover">
          <p class="sub">電話番号<span>(※必須)</span></p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.tel"
              type="text"
              class="row1"
              placeholder="0123456789（ハイフンなし）"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.tel }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">連絡先メールアドレス<span>(※必須)</span></p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.mail"
              type="email"
              class="row1"
              placeholder="例）sample@gmail.com"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.mail }}
          </div>
        </div>

        <!-- お問い合わせ区分 -->
        <div class="input-cover">
          <p class="sub">お問い合わせ区分<span>(※必須・複数選択可)</span></p>
          <div v-if="pageType === 0" class="row-cover check">
            <label v-for="(typeData, index) in typeList" :key="index">
              <input
                type="checkbox"
                v-model="checkedTypeList"
                :value="typeData"
              /><span style="margin-right:20px;" >{{ typeData.name }}</span></label
            >
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <div
              v-for="(checkedTypeData, index) in checkedTypeList"
              :key="index"
            >
              {{ checkedTypeData.name }}
            </div>
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">ご希望の型式<span>(※必須)</span></p>
          <div v-if="pageType === 0" class="row-cover check">
            <p class="model-name" style="margin: 0;">屋外設置型</p>
            <div class="check-label">
              <div v-for="item in this.outdoorTypeList" :key="item.value" class="model-label">
                <label class="">
                  <input
                    v-model="form.desiredModel"
                    type="checkbox"
                    :value="item.value"
                  />
                  <span style="margin-right:20px;" >{{ item.name }}</span>
                </label>
              </div>
            </div>
            <p class="model-name">家庭用簡易型</p>
            <div class="check-label">
              <div v-for="item in this.homeTypeList" :key="item.value" class="model-label">
                <label class="">
                  <input
                    v-model="form.desiredModel"
                    type="checkbox"
                    :value="item.value"
                  />
                  <span style="margin-right:20px;" >{{ item.name }}</span>
                </label>
              </div>
            </div>
            <p class="model-name">携帯型折りたたみ式ダンボール</p>
            <div class="check-label">
              <div v-for="item in this.portableTypeList" :key="item.value" class="model-label">
                <label class="">
                  <input
                    v-model="form.desiredModel"
                    type="checkbox"
                    :value="item.value"
                  />
                  <span style="margin-right:20px;" >{{ item.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <ul>
              <li v-for="item in form.desiredModel" :key="item.value">{{ item }}</li>
            </ul>
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">設置場所<span>(※必須)</span></p>
          <div v-if="pageType === 0">
            <textarea
              class="location"
              v-model="form.location"
              placeholder="設置場所を入力してください。"
            ></textarea>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.location }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">1日あたりの使用想定回数<span>(※必須)</span></p>
          <div v-if="pageType === 0" class="row-cover">
            <select v-model="form.estimatedNumber" class="select">
              <option v-for="item in estimatedNumberList" :key="item.value" :value="item.value">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.estimatedNumber }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">担当者との電話を希望する<span>(※必須)</span></p>
          <div v-if="pageType === 0" class="row-cover">
            <select v-model="form.tellRequest" class="select">
              <option v-for="item in tellRequestList" :key="item.value" :value="item.value">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.tellRequest }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">お問い合わせ内容<span>(※必須)</span></p>
          <div v-if="pageType === 0">
            <textarea
              v-model="form.description"
              placeholder="こちらにお問い合わせ内容を入力してください。"
            ></textarea>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.description }}
          </div>
        </div>
      </div>
      <div v-if="pageType === 0">
        <p style="text-align: center">
          記入が終わりましたら、下の「確認」を押し、ご記入内容をご確認ください。
        </p>
      </div>
      <div @click="confirm" class="button" v-if="pageType === 0">
        <!-- <div class="button" v-if="pageType === 0"> -->
        <div><p>確認</p></div>
      </div>
      <div class="soushin" v-if="pageType === 1">
        <div @click="back" class="button"><p>修正</p></div>
        <div @click="send" class="button"><p>送信</p></div>
        <!-- @click="send"を本番は復活させること -->
      </div>
      <div v-if="pageType !== 2">
        <p class="annotation">なるべく低コストで製品をご提供するために、当社は電話の窓口を設けていません。</p>
        <p class="annotation">まずはお問い合わせフォームからのご連絡をお願いいたします。</p>
        <p class="annotation">担当者との電話連絡を希望する場合は、こちらからお電話を差し上げます。</p>
      </div>
      <div v-else-if="pageType === 2" class="complete">
        <h3 style="margin-bottom: 50px; text-align: center">
          お問い合わせを承りました
        </h3>
        <p>
          お問い合わせ内容を、ご入力のメールアドレスに送信しました。<br />20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
        </p>
        <!-- <router-link to="/"> -->
        <div class="button2" @click="top">トップに戻る</div>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
export default {
  name: "CotacntForm",
  components: {},
  props: {
    type: Number,
  },
  data() {
    return {
      pageType: 0,
      form: {
        companyName: "",
        name: "",
        tel: "",
        mail: "",
        desiredModel: ref([]),
        location: "",
        estimatedNumber: "",
        tellRequest: "",
        checkedTypeData: "",
        description: "",
      },
      telPattern: /^0\d{9,10}$/,
      outdoorTypeList: [ // 屋外設置型
        { name: 'バイオレタリー100', value: 'バイオレタリー100' },
        { name: 'バイオレタリーSR', value: 'バイオレタリーSR' },
        { name: 'バイオレタリーW100', value: 'バイオレタリーW100' },
        { name: 'バイオレタリーWSR', value: 'バイオレタリーWSR' },
      ],
      homeTypeList: [ // 家庭用簡易型
        { name: 'バイオレタリー20', value: 'バイオレタリー20' },
      ],
      portableTypeList: [ // 携帯型折りたたみ式ダンボール
        { name: 'バイオレタリーD35', value: 'バイオレタリーD35' },
        { name: 'バイオレタリーD20', value: 'バイオレタリーD20' },
      ],
      estimatedNumberList: [ // 携帯型折りたたみ式ダンボール
        { name: '選択してください', value: '' },
        { name: '1～10回', value: '1～10回' },
        { name: '11～30回', value: '11～30回' },
        { name: '30～50回', value: '30～50回' },
        { name: '50～100回', value: '50～100回' },
        { name: '100回以上', value: '100回以上' },
      ],
      tellRequestList: [ // 携帯型折りたたみ式ダンボール
        { name: '選択してください', value: '' },
        { name: '希望する', value: '希望する' },
        { name: '希望しない', value: '希望しない' },
      ],
      typeList: [
        {
          id: 0,
          name: "資料請求",
        },
        {
          id: 1,
          name: "お問い合わせ",
        },
        {
          id: 2,
          name: "お見積り、商談",
        },
        {
          id: 9,
          name: "その他",
        },
      ],
      checkedTypeList: [],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      // alert(this.form.companyName);
      // console.log(`company name: ${this.form.companyName}`);
      // console.log(`company name: ${this.form}`);//※ダメなやつ

      console.log(this.form);

      let validation_ok = true;
      let validation_message = "";
      console.log(validation_ok);
      console.log(validation_message);

      //お名前チェック
      if (this.form.name !== "") {
        console.log("お名前 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お名前」";
      }
      //電話番号チェック
      if (this.form.tel !== "") {
        if (this.telPattern.test(this.form.tel)) {
          console.log("電話番号 ok");
        } else {
          console.log(this.form.tel);
          validation_ok = false;
          alert(`電話番号は正しい形式で入力してください`);
          return;
        }
      } else {
        validation_ok = false;
        validation_message = validation_message + "「電話番号」";
      }
      //メールアドレスチェック
      if (this.form.mail !== "") {
        var mailAddress = this.form.mail;
        var hotResult = mailAddress.indexOf("hotmail.co.jp");
        var outResult = mailAddress.indexOf("outlook.jp");

        // hotmail,outlookチェック
        if (hotResult === -1 && outResult === -1) {
          //含まれていない
        } else {
          validation_ok = false;
          alert(`hotmail. outlookは使えません`);
          return;
        }

        console.log("メールアドレス ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「メールアドレス」";
      }
      console.log(this.form.desiredModel);
      //ご希望の型式 チェック
      if (this.form.desiredModel.length !== 0) {
        console.log("ご希望の型式 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「ご希望の型式」";
      }
      //設置場所 チェック
      if (this.form.location !== "") {
        console.log("設置場所 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「設置場所」";
      }
      //1日あたりの使用想定回数 チェック
      if (this.form.estimatedNumber !== "") {
        console.log("1日あたりの使用想定回数 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「1日あたりの使用想定回数」";
      }
      //担当者との電話を希望する チェック
      if (this.form.tellRequest !== "") {
        console.log("担当者との電話を希望する ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「担当者との電話を希望する」";
      }
      // //お問い合わせ区別チェック
      // if (this.form.type !== "") {
      //   console.log("お問い合わせ区分 ok");
      // } else {
      //   validation_ok = false;
      //   validation_message = validation_message + "「お問い合わせ区分」";
      // }
      //購入内容チェック
      // if (this.form.type === 0) {
      //   if (
      //     this.form.bottle1Num !== 0 ||
      //     this.form.bottle2Num !== 0 ||
      //     this.form.bottle3Num !== 0
      //   ) {
      //     console.log("購入内容 ok");
      //   } else {
      //     validation_ok = false;
      //     validation_message = validation_message + "「購入本数」";
      //   }
      // } else {
      //   noaction
      // }
      //内容チェック
      if (this.form.description !== "") {
        console.log("お問い合わせ内容 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ内容」";
      }

      //結果発表
      if (validation_ok === true) {
        this.pageType = 1;
        return;
      } else {
        alert(`${validation_message}を入力してください`);
        return;
      }
    },
    send() {
      console.log(this.checkedTypeList);
      console.log(this.rootUrl);
      let params = new URLSearchParams();
      params.append("company_name", this.form.companyName);
      params.append("name", this.form.name);
      params.append("tel", this.form.tel);
      params.append("mail", this.form.mail);
      params.append("location", this.form.location);
      params.append("estimatedNumber", this.form.estimatedNumber);
      params.append("tellRequest", this.form.tellRequest);
      // params.append("type", this.form.type);
      params.append("type_list", JSON.stringify(this.checkedTypeList));
      params.append("desiredModel", JSON.stringify(this.form.desiredModel));
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          console.log(response.status);
          console.log(response.statusText);
          // alert(response.statusText);
          if (response.status === 200) {
            // console.log('ok')
            this.pageType = 2;
            return;
          } else if (
            response.status === 400 ||
            response.status === 500
          ) {
            alert(response.status.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.pageType = 0;
    },
    top() {
      this.$scrollTo("#app", 500, { easing: "ease" });
      this.pageType = 0;
      this.form = {
        companyName: "",
        name: "",
        tel: "", // 電話番号
        mail: "",
        desiredModel: ref([]), // ご希望の型式
        location: "", // 設置場所
        estimatedNumber: "", // 1日あたりの使用想定回数
        tellRequest: "", //担当者との電話を希望する
        checkedTypeData: "",
        description: "",
        // type: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .disabled {
    color: red;
    font-weight: bold;
  }
  .title {
    padding-bottom: 30px;
    padding-left: 0;

    @media screen and (max-width: 767px) {
      padding-bottom: 5px;
    }

    h3 {
      padding-top: 15px;
      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }
    }
  }

  .form {
    margin-bottom: 30px;
    margin-top: 50px;

    .input-cover {
      text-align: left;
      margin-bottom: 30px;
      .sub {
        font-size: 18px;
        margin-bottom: 5px;
      }
      input,
      textarea {
        border: none;
      }
      .row-cover,
      .confirmation {
        border: 1px solid #a7a5a4;
      }
      .model-name {
        font-size: 18px;
        margin-top: 10px;
      }
      .check-label {
        display: flex;
        @media screen and (max-width: 1100px) {
          display: block;
        }
      }
      .model-label {
        margin-right: 10px;
      }
      .check {
        padding: 20px 10px;
        font-size: 18px;
        background-color: white;
      }
      .select {
        display: inline-block;
        position: relative;
        border: 1px solid #eee;
        width: 100%;
        // height: 70px;
        vertical-align: middle;
      }
      .select::before {
        position: absolute;
        top: 22px;
        right: 16px;
        width: 0;
        height: 0;
        border-width: 15px 10px 0 10px;
        border-style: solid;
        border-color: var(--main) transparent transparent transparent;
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        min-width: 300px;
        padding: 20px 15px;
        padding-right: 1em;
        border: 1px solid #a7a5a4;
        outline: 0;
        background: #fff;
        background-image: none;
        box-shadow: none;
        text-indent: 0.01px;
        text-overflow: ellipsis; // 文字の省略
        cursor: pointer;
        font-size: 18px;
        @media screen and (max-width: 767px) {
          min-width: 250px;
        }
      }
      .row1,
      textarea {
        width: 100%;
        padding: 20px 10px;
        font-size: 20px;
        resize: auto;
        cursor: text;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        column-count: initial !important;
        word-break: break-all;
      }
      textarea {
        border: 1px solid #a7a5a4;
        height: 300px;
        text-rendering: auto;

        @media screen and (max-width: 767px) {
          margin: 0;
        }
      }
      .location {
        height: 150px;
      }
      .sp {
        &:nth-child(n + 2) {
          margin-left: 20px;
          @media screen and (max-width: 767px) {
            margin-left: 0;
          }
        }

        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }
  .button,
  .button2 {
    border: 1px solid var(--sub);
    color: var(--sub);
    // padding: 20px;
    width: 200px;
    text-align: center;
    margin: 50px auto;
    cursor: pointer;
    transition: 0.8s;
    p {
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 200px;
      padding: 0px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      color: white !important;
      background-color: var(--sub);
      transition: 0.8s;
    }
  }

  .btn-disabled {
    width: 200px;
    text-align: center;
    border: 1px solid var(--sub);
    margin: 50px auto;
    color: var(--sub);
    background-color: #c6c6c6;
    cursor: not-allowed;
    transition: 0.8s;
    p {
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 200px;
      padding: 0px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .soushin {
    display: flex;
    justify-content: space-between;
    .button,
    .button2 {
      width: 150px;
      @media screen and (max-width: 767px) {
      }
      width: 40%;
      font-size: 18px;
    }
  }

  .annotation {
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: start;
    }
  }

  .complete {
    text-align: left;
    h2 {
      @media screen and (max-width: 767px) {
        font-size: 25px;
      }
    }
    a {
      text-decoration: none;
      color: black;

      .button2 {
        width: 170px;
        margin-top: 70px;
        margin-bottom: 50px;
      }
    }
    p {
      text-align: center;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}

.confirmation {
  padding: 20px;
}
</style>
