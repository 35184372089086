<template>
  <div class="okugai-explanation">
    <div class="item-cover pt-50">
      <img
        alt="自然イメージ"
        src="@/assets/okugai/bioletry-kyotu-ira001.png"
        class="number"
      />
      <div class="text">
        <h3>希少な<br />家庭用バイオトイレ</h3>
        <p>
          家庭用のバイオトイレは世の中で多く製造されていなくて希少です。バイオトイレは、従来の家庭用簡易型トイレのデメリットがありません。臭いがしない。汲み取りも後処理も不要。水を使わないので水道蛇口も排水溝も不要。ダイオキシンも発生しない。このようなメリットを享受できます。
        </p>
      </div>
      <img
        alt="家庭イメージ"
        src="@/assets/katei/bioletry-katei-006.jpg"
        class="zu"
      />
    </div>
    <div class="item-cover border">
      <img
        alt="01"
        src="@/assets/okugai/bioletry-kyotu-ira002.png"
        class="number"
      />
      <div class="text">
        <h3>様々な用途で<br />使いやすい形状と仕様</h3>
        <p>
          タライ状の攪拌槽を設け、コンパクトな設計になっています。
        </p>
      </div>
      <div class="img-cover">
        <img
          alt="問い合わせ殺到中"
          src="@/assets/katei/bioletry-katei-003.png"
          class="coment"
        />
        <img
          alt="キャンピングカー"
          src="@/assets/katei/bioletry-katei-007.jpg"
          class="camp"
        />
      </div>
    </div>

    <div class="item-cover2">
      <img
        alt="03"
        src="@/assets/okugai/bioletry-kyotu-ira003.png"
        class="number"
      />
      <div class="text">
        <h3>菌床の交換と追加で<br class="sp-only">継続使用可能</h3>
        <p>
          菌床は、週1回の補充と、年間1～2回の交換で継続使用ができます。菌床の補充と交換をすれば、ずっと快適な状態で使用できます。※菌床は別途購入する必要がございます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "OkugaiExplanation",
  components: {
    // UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.okugai-explanation {
  .item-cover,
  .item-cover2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 60px 0;
    }
    .number {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
      }
    }
    h3 {
      color: var(--sub);
      @media screen and (max-width: 767px){
        text-align: center;
      }
    }
  }
  .item-cover {
    .text {
      width: 45%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .zu,
    .img-cover {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
    }
    .zu{
        @media screen and (min-width:768px) and ( max-width:1024px){
  margin-top: 50px;
  }
    }
    .img-cover{
      @media screen and(max-width: 767px){
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
  }
  // &:nth-last-child(2){
  //  border-top: 1px var(--main) solid;
  // }
  .item-cover2 {
    border-top: 1px var(--main) solid;
    .text {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.border{
    border-top: 1px var(--main) solid;
}
.pt-50{
  padding-top: 50px!important;
}
.coment{
  @media screen and(max-width: 767px){
    width: 70%;
    text-align: center;
  }
}
</style>
