<template>
  <div class="okugai-etc">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'業界最安値に挑戦しています'"
      class="space-middle-bottom-img"
    />
    <p class="space-bottom-200">
      バイオレタリーは業界最安値に挑戦していますので、すでに取得済の他社の見積書があれば、同スペックならばその価格よりも安く製品を提供できるように努力させていただきます。お気軽にお問い合わせくださいませ。ボリュームディスカウントもご相談を承ります。
    </p>
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'メンテナンスについて'"
      class="space-middle-bottom-img"
    />
    <p>
      屋外設置型バイオトイレは、年間168,000円のメンテナンス契約が必要になり、以下の内容のメンテンナスを当社にて行います。
    </p>
    <ul>
      <li>年に1～2回、菌床の入替えが必要</li>
      <li>機械部分の定期点検を行います</li>
      <li>週1回交換する菌床を、月に1回配送</li>
    </ul>
    <p class="space-bottom-200">メンテナンスは、すべて当社にて行います。</p>

    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'消耗品・オプション品<br class=sp-only>について'"
      class="space-middle-bottom-img"
    />
    <p class="space-bottom-200 bt-0">
      菌床は消耗品ですが、上記のメンテンスに菌床の提供・交換が含まれるため、消耗品やオプション品を別途購入していただく必要はございません。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "OkugaiEtc",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.okugai-top {
  .cover {
    background-color: var(--sub);
    padding-left: calc(calc(100vw - 1100px) * 0.5);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h1 {
      padding: 50px 0;
      color: white;
    }
    img {
      width: 30%;
      padding-right: 100px;
    }
  }
  .middle-cover {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -150px;
    margin-bottom: 50px;
    p {
      width: 70%;
      // margin-top: 100px;
    }
    .right {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      // margin-right: 100px;
      .bio {
        width: 45%;
        height: auto;
      }
      .tokkyo {
        width: 100px;
        margin-bottom: 30px;
        margin-right: 60px;
      }
    }
  }
  .img-cover {
    display: flex;
    justify-content: space-between;
    img {
      width: 31%;
    }
  }
}
.bt-0{
  padding: 0;
}

</style>
