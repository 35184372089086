<template>
  <div class="katei-list space-top-bottom-set-200">
    <h3>バイオレタリー20</h3>

    <div class="slider">
      <carousel
        :autoplay="true"
        :loop="true"
        :per-page="1"
        :navigation-enabled="true"
        :pagination-padding="0"
        :speed="1000"
        pagination-color="#3e3a39"
        pagination-active-color="#003f96"
      >
        <!-- <slide> <img :src="`${rootUrl}/img/slider-1.jpeg`" alt="" class="slide-img"/> </slide> -->
        <slide>
          <img
            src="@/assets/katei/bioletry-katei-004.jpg"
            alt=""
            class="slide-img"
          />
        </slide>
        <slide>
          <img
            src="@/assets/katei/bioletry-katei-005.jpg"
            alt=""
            class="slide-img"
          />
        </slide>
                <slide>
          <img
            src="@/assets/katei/bioletry-katei-004.jpg"
            alt=""
            class="slide-img"
          />
        </slide>
        <slide>
          <img
            src="@/assets/katei/bioletry-katei-005.jpg"
            alt=""
            class="slide-img"
          />
        </slide>

        <!-- <slide>
          <img
            src="@/assets/katei/bioletry-katei-006.jpg"
            alt=""
            class="slide-img"
          />
        </slide> -->
      </carousel>
    </div>
    <div class="wrap">
      <div class="item">
        <UiTable3 :tableList="tableList" class="table" />
        <UiTable3 :tableList="tableList2" class="table" />
      </div>
      <p class="price">
        定価：305,000円<span>&nbsp;(税込)&emsp;※送料別</span>
      </p>
      <p class="note">
        送料別途。納入場所によって異なるため、詳細はお問い合わせくださいませ。<br>
        処理能力は目安です。季節・天候・使用条件などによって変わります。<br>
        室内での利用（気温10度～35度の環境）で使用してください。<br>
        倉庫や屋外など気温の変動がある環境では使用ができません。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import UiTable3 from "@/components/Ui/Table3.vue";
export default {
  name: "KateiList",
  components: {
    UiTable3,
    Carousel,
    Slide,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリー20",
        },
        {
          id: 2,
          th: "品名",
          td: "消滅型 家庭用バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W50×D65×H40",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "重量",
          td: "25kg",
        },
        {
          id: 2,
          th: "処理能力/日",
          td: "8回（1日2人×4回使用）",
        },
        {
          id: 3,
          th: "電源",
          td: "AC100V（300W）",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.katei-list {
  h3 {
    text-align: center;
    color: white;
  }
}
.slider {
  margin: 50px auto;
  width: 40%;
  @media screen and (max-width: 767px) {
    width: 80%;
  }
}
/deep/.VueCarousel-dot-container {
  width: 150px;
  display: flex !important;
  justify-content: space-between;
  padding-top: 20px !important;
  @media screen and (max-width: 767px) {
    width: 100px;
  }
}
.item {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .table {
    width: 47%;
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 0;
    }
  }
}
.price {
  width: 100%;
  background-color: var(--sub2);
  color: white;
  font-size: 20px;
  padding: 10px 0;
  margin: 30px 0 0;
  text-align: center;
  span {
    font-size: 14px;
  }
}
.note {
  text-align: center;
  color: white;
  margin-top: 10px;
  @media screen and(max-width: 767px){
    text-align: start;
    line-height: 1.8em;
  }
}
</style>
