<template>
  <div class="katei-explanation">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'使用方法'"
      class="space-middle-bottom-img"
    />
    <img src="@/assets/keitai/bioletry-keitai-012.jpg" alt="入ってるモノ" class="main-img pc-only"/>
    <img src="@/assets/keitai/bioletry-keitai-012sp.jpg" alt="入ってるモノ" class="main-img sp-only"/>
    <p class="sp-only howto">①上蓋 ②下蓋 ③立壁 ④水に流せるティッシュ×4個、汚物袋 ⑤使用後投入菌床50g×4個 ⑥初期投入菌床400g⑦初期投入菌活性剤200cc</p>
    <div class="block">
      <div class="left dark">
        <p>使用前の準備</p>
      </div>
      <div class="right">
        <div class="item-cover">
          <div class="item">
            <p class="title"><ol><li>①汚物袋に初期投入菌床を入れる</li></ol></p>
            <p class="text">
              汚物袋に初期投入菌床を入れます。塊がある場合は、袋をもんでつぶしながら、入れてください。
            </p>
          </div>
          <img
            src="@/assets/keitai/bioletry-keitai-010.jpg"
            alt="入ってるモノ"
          />
        </div>
        <div class="item-cover">
          <div class="item">
            <p class="title"><ol><li>②菌活性剤（液体）を全体にふりかける</li></ol></p>
            <p class="text">
              菌活性剤（液体）を全体にふりかけます。ふりかけた後、箱を持ち、ゆすってかき混ぜます。
            </p>
          </div>
          <img
            src="@/assets/keitai/bioletry-keitai-011.jpg"
            alt="入ってるモノ"
          />
        </div>

        <div class="item2">
          <p class="title"><ol><li>③１時間程度放置</li></ol></p>
          <p class="text">
            １時間程度放置します。バイオ菌（バクテリア・微生物）（バクテリア・微生物）は１時間程度で目覚めて活性化します。緊急時は、用を足してから菌床と菌活性剤をいれても処理に少々時間が掛かりますが問題は、ありません。
          </p>
        </div>
      </div>
    </div>
    <div class="sankaku-cover">
      <div class="sankaku"></div>
    </div>
    <div class="block-center">
      <div class="left light">
        <p>使用後の処理</p>
      </div>
      <div class="right">
        <div class="item-cover2">
          <div class="item">
            <p class="title">
              小便は何もする必要がありません。大便は使用後に使用後投入菌床50gをまんべんなく、大便が隠れるようにふりかけます。
            </p>
          </div>
          <img
            src="@/assets/keitai/bioletry-keitai-009.jpg"
            alt="入ってるモノ"
          />
        </div>
      </div>
    </div>
    <div class="sankaku-cover">
      <div class="sankaku"></div>
    </div>
    <div class="block-center">
      <div class="left green">
        <p>最終処理</p>
      </div>
      <div class="right">
        <div class="item-cover">
          <div class="item2">
            <p class="title">
              使用後投入菌床があれば、何度でも使用できます。最後に捨てる際は、菌床は、米糠とおがくずでできているので、肥料として畑などにまく、または、可燃ゴミとして処理できます。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "KateiExplanation",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.katei-explanation {
  .main-img{
    margin-bottom: 50px;
    @media screen and (max-width: 767px){
      margin-bottom: 0;
    }
  }
  .howto{
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
    color: var(--sub);
    font-size: 18px;
  }
  }
  .block,
  .block-center {
    width: 80%;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    // align-items: flex-start;
    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
    }
    .left {
      padding: 50px 0;
      width: 80px;
      // height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
            align-items: center;
      @media screen and(max-width: 767px) {
        width: 100%;
        padding: 20px 0;
      }
      p {
        color: white;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        // letter-spacing: 0.4em;
        font-size: 20px;
                @media screen and (max-width: 767px) {
          writing-mode: horizontal-tb;
          -ms-writing-mode: horizontal-tb;
        font-size: 24px;
        }
      }
    }
    .right {
      width: calc(100% - 120px);
      padding: 20px 0 20px 30px;
            @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-top: 20px;
      }
      .item,
      .item2 {
        .title {
          color: var(--sub);
          font-size: 22px;
          line-height: 1.5em;
          width: 100%;
        }
        .text {
          margin-top: 10px;
          // margin-bottom: 50px;
          margin-left: 28px;
                  @media screen and(max-width: 767px){
          margin-left: 1.5em;
          margin-bottom: 0px;
          line-height: 1.7em;
          width: 95%;
        }
        }
      }
      .item {
        width: 60%;
        @media screen and (max-width: 767px) {
    width: 95%;
    }
      }
      .item2 {
        width: 100%;
      }
      .item-cover,
      .item-cover2 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
      flex-direction: column;
    }
        img {
          width: 25%;
          @media screen and (max-width: 767px) {
                width: 60%;
    margin: 0 auto;
    margin-top: 20px;

    }
        }
      }
      .itemcover {
        align-items: flex-start;
      }
      .item-cover2 {
        align-items: center;
      }
    }
  }
  .dark {
    background-color: var(--sub);
  }
  .light {
    background-color: var(--sub2);
  }
  .green {
    background-color: #00973b;
  }
  .sankaku-cover {
    width: 80%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
  .sankaku {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 20px;
    border-color: var(--sub) transparent transparent transparent;
    margin: 10px 20px;
    // width: 80%;
  }
}
ol li  {
  @media screen and (max-width: 767px) {
    list-style: none;
    padding-left: 1.1em;
    text-indent: -1.1em;
  }
}
</style>
