<template>
  <div class="ui-table4">
    <div class="table-inner">
      <table>
        <tr v-for="table in tableList" :key="table.id">
          <th>
            <div>{{ table.th }}</div>
          </th>
          <td>
            <div v-html="table.td"></div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTable4",
  components: {},
  props: {
    tableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  // color: white;
  tr {
    text-align: left;
    th,
    td {
      padding: 8px 0;
      padding-left: 25px;
      border-top: solid 1px var(--main);
      border-bottom: solid 1px var(--main);
      vertical-align: middle;

      @media screen and (max-width: 767px) {
        display: block;
        padding: 16px 0;
        border: none;
        padding-left: 0px;
      }
    }
    th {
      width: 15%;
      min-width: 128px;
      text-align: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        background-color: #dfdfdf;
        padding: 5px 0;
        color: var(--sub);
      }
      div {
        @media screen and (max-width: 767px) {
          padding: 0 16px;
        }
      }
    }
    td {
      @media screen and (max-width: 767px) {
        border-top: none;
        display: flex;
        justify-content: center;
        padding: 10px 0;
      }
      div {
        border-left: solid 1px var(--main);
        padding-left: 20px;
        height: 50px !important;
        line-height: 20px;
        display: flex;
        align-items: center;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          padding-left: 10px;
          font-size: 15px;
        }
        @media screen and (max-width: 767px) {
          //  width: 90%;
          border-left: none;
          padding-left: 0;
          padding-bottom: 5px;
        }
      }
    }
  }
  tr:last-child {
    border-bottom: solid 1px white;
    @media screen and (max-width: 767px) {
      border: none;
    }
  }
}
</style>
