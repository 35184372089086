<template>
  <div class="home-list">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'製品一覧'"
      class="space-middle-bottom-text"
    />
    <div class="top-cover">
      <div class="left">
        <h3>屋外設置型バイオトイレ</h3>
        <p>
          建設現場・離島・キャンプ場・イベント会場などに使用する、屋外設置型のバイオトイレです。臭わない、汲み取り・後処理不要、水なしで使用できることはもちろん、独自の撹拌部分の構造によって、軽トラックで運搬・移動できるコンパクトサイズです。そのため、通常の屋外トイレでは運搬が難しい場所への設置ができます。
        </p>
      </div>
      <div class="right">
        <img alt="自然アイコン" src="@/assets/bioletry-top-pa001.png" />
        <img src="@/assets/bioletry-okugai-03.png" alt="特許出願中" class="top-logo">
      </div>
    </div>

    <div class="item-cover">
      <div class="item first">
        <img
          alt="バイオレタリー100"
          src="@/assets/bioletry-top-002.webp"
          class="item-img"
        />
        <UiTable2 :tableList="tableList" />
        <p class="price">
          定価：2,380,000円<span>&nbsp;(税込)&emsp;※送料別</span>
        </p>
      </div>
      <div class="item first">
        <img
          alt="バイオレタリーSR"
          src="@/assets/okugai/bioletry-okugai-013.webp"
          class="item-img"
        />
        <UiTable2 :tableList="tableList2" />
        <p class="price">
          定価：3,170,000円<span>&nbsp;(税込)&emsp;※送料別</span>
        </p>
      </div>
    </div>
    <div class="item-cover mt">
      <div class="item first">
        <img
          alt="バイオレタリーW100"
          src="@/assets/bioletry-top-003.webp"
          class="item-img"
        />
        <UiTable2 :tableList="tableList3" />
        <p class="price">
          定価：2,800,000円<span>&nbsp;(税込)&emsp;※送料別</span>
        </p>
      </div>
      <div class="item">
        <img
          alt="バイオレタリーWSR"
          src="@/assets/bioletry-top-020.webp"
          class="item-img"
        />
        <UiTable2 :tableList="tableList4" />
        <p class="price">
          定価：3,320,000円<span>&nbsp;(税込)&emsp;※送料別</span>
        </p>
      </div>
    </div>



    <div class="link-button">
      <router-link to="/okugai">屋外設置型バイオトイレの詳細を見る</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
import UiTable2 from "@/components/Ui/Table2.vue";
export default {
  name: "HomeList",
  components: {
    UiHeading,
    UiTable2,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリー100",
        },
        {
          id: 2,
          th: "品名",
          td: "消滅型 屋外用バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W90×D170×H200",
        },
        {
          id: 4,
          th: "重量",
          td: "280kg",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリーSR",
        },
        {
          id: 2,
          th: "品名",
          td: "消滅型 屋外用 バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W90×D170×H200",
        },
        {
          id: 4,
          th: "重量",
          td: "330kg",
        },
      ],
      tableList3: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリーW100",
        },
        {
          id: 2,
          th: "品名",
          td: "消滅型 屋外用大小分離一体型バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W120×D175×H185",
        },
        {
          id: 4,
          th: "重量",
          td: "330kg",
        },
      ],
      tableList4: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリーWSR",
        },
        {
          id: 2,
          th: "品名",
          td: "ソーラー付 バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W122×D178×H185",
        },
        {
          id: 4,
          th: "重量",
          td: "380kg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.top-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
  .left {
    width: 80%;
    h3 {
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    color: var(--sub);
    margin-bottom: 30px;
  }
  .right {
    width: 15%;
    aspect-ratio: 1 / 1;
    position: relative;
    @media screen and (max-width: 767px) {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
    }
    .top-logo {
      width: 50%;
      position: absolute;
      bottom: 0;
      right: -20%;
    }
  }
}
.item-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .item {
    width: 48%;
    text-align: center;
    .item-img {
      width: 100%;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .price {
      // width: calc(100% - 20px);
      width: 100%;
      background-color: var(--sub);
      color: white;
      font-size: 20px;
      padding: 10px 0;
      margin: 10px 0;
      span {
        font-size: 14px;
      }
    }
  }
}
.first {
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.mt{
margin-top: 100px;
@media screen and(max-width: 767px){
  margin-top: 0px;
}
 }
</style>
