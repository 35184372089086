<template>
  <div class="katei-explanation">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'使用方法'"
      class="space-middle-bottom-img"
    />
    <!-- <div class="block-center">
      <div class="left dark">
        <p>使用前の準備</p>
      </div>
      <div class="right">
        <p class="title2">
         上蓋を取り、中身を出して、汚物袋をセットし、初期投入菌床と菌活性剤を入れるだけで準備OK
        </p>
      </div>
    </div>
    <div class="sankaku"></div> -->
    <div class="block-center">
      <div class="left light">
        <p>使用後の処理</p>
      </div>
      <div class="right">
        <p class="title2">
          小便は何もする必要がありません。大便は使用後に使用後投入菌床50gをまんべんなく、大便が隠れるようにふりかけます。
        </p>
      </div>
    </div>
    <div class="sankaku"></div>
    <div class="block-center">
      <div class="left green">
        <p>最終処理</p>
      </div>
      <div class="right">
        <p class="title2">
          使用後投入菌床があれば、何度でも使用できます。最後に捨てる際は、菌床は、米糠とおがくずでできているので、肥料として畑などにまく、または、可燃ゴミとして処理できます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "KateiExplanation",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.katei-explanation {
  .block,
  .block-center {
    display: flex;
    justify-content: space-between;
    // align-items: flex-start;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .left {
      padding: 50px 0;
      width: 80px;
      // height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and(max-width: 767px) {
        width: 100%;
        padding: 20px 0;
      }
      p {
        color: white;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        // letter-spacing: 0.4em;
        font-size: 20px;
        @media screen and (max-width: 767px) {
          writing-mode: horizontal-tb;
          -ms-writing-mode: horizontal-tb;
        font-size: 24px;
        }
      }
    }
    .right {
      width: calc(100% - 120px);
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }

      .title,.title2 {
        color: var(--sub);
        font-size: 28px;
        line-height: 1.5em;
        @media screen and(max-width: 767px){
          font-size: 24px;
          
          // font-weight: bold;
        }
      }
      .title2{
        font-size: 22px;
        @media screen and(max-width: 767px){
          font-size: 20px;
        }
      }
      .text {
        margin-bottom: 50px;
        margin-left: 28px;
        @media screen and(max-width: 767px){
          margin-left: 1.5em;
          margin-bottom: 0px;
          line-height: 1.7em;
        }
      }
      .mb{
        @media screen and (max-width: 767px){
          margin-bottom: 20px;
      }
      }
    }
  }
  .block-center {
    align-items: center;
  }
  .dark {
    background-color: var(--sub);
  }
  .light {
    background-color: var(--sub2);
  }
  .green {
    background-color: #00973b;
  }
  .sankaku {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 20px;
    border-color: var(--sub) transparent transparent transparent;
    margin: 10px 20px;
    @media screen and (max-width: 767px){
      margin: 20px auto;
    }
  }
}
ol li  {
  @media screen and (max-width: 767px) {
    list-style: none;
    padding-left: 1.1em;
    text-indent: -1.1em;
  }
}
</style>
