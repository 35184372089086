<template>
  <div class="home-list">
    <div class="top-cover">
      <div class="left">
        <h3>携帯型折りたたみ式<br class="sp-only" />ダンボールバイオトイレ</h3>
        <p>
          ダンボールによる折りたたみ式で持ち運びができるバイオトイレです。従来の携帯用トイレとは、仕組みが全く異なり、臭わず、便を減容します。すぐに準備でき、秒速で臭いが消えます。そのため、車内、トラック、キャンプや登山や釣りなど、さまざまな用途で使用できます。
        </p>
      </div>
      <img alt="車アイコン" src="@/assets/bioletry-top-pa003.png" />
    </div>

    <div class="item-cover">
      <div class="item first">
        <img alt="バイオレタリーD35" src="@/assets/bioletry-top-006.png" />
        <UiTable2 :tableList="tableList" />
        <p class="price">定価：7,500円<span>&nbsp;(税込)&emsp;※送料別</span></p>
      </div>
      <div class="item">
        <img alt="バイオレタリーD20" src="@/assets/bioletry-top-007.png" />
        <UiTable2 :tableList="tableList2" />
        <p class="price">定価：6,300円<span>&nbsp;(税込)&emsp;※送料別</span></p>
      </div>
    </div>
    <div class="link-button">
      <router-link to="/keitai"
        >携帯型折りたたみ式バイオトイレの<br
          class="sp-only"
        />詳細を見る</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable2 from "@/components/Ui/Table2.vue";
export default {
  name: "HomeList",
  components: {
    UiTable2,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリーD35",
        },
        {
          id: 2,
          th: "品名",
          td: "<span>災害時用 折りたたみ式<br>減容型 ダンボール バイオトイレ</span>",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "通常：W40×D37×H35<br>折りたたみ時：W40×D37×H12",
        },
        {
          id: 4,
          th: "重量",
          td: "2.0kg",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリーD20",
        },
        {
          id: 2,
          th: "品名",
          td: "非常時用 減容型 ダンボール バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W25×D25×H20",
        },
        {
          id: 4,
          th: "重量",
          td: "1.5kg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.top-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
  .left {
    width: 80%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    h3 {
      color: var(--sub);
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
  }
  img {
    width: 15%;
    @media screen and (max-width: 767px) {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }
}
.item-cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .item {
    width: 48%;
    text-align: center;
    .item-img {
      width: 100%;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .price {
      width: 100%;
      background-color: var(--sub);
      color: white;
      font-size: 20px;
      padding: 10px 0;
      margin: 10px 0;
      span {
        font-size: 14px;
      }
    }
  }
}

.first {
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.link-button {
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}
</style>
