<template>
  <div class="home-requ-info">
    <div class="wrap cover">
      <p class="title">製品の資料請求が<br />できます</p>
      <p class="text">
        資料にて、バイオレタリー製品のより詳しい情報をお届けしています。資料請求はもちろん無料です。お気軽にご請求くださいませ。
      </p>
      <router-link to="/" v-scroll-to="'#contact'">
        <img src="@/assets/bioletry-top-pa004.png" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeRequInfo",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-requ-info {
  // background-color: var(--sub2);
  color: white;

  .cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    @media screen and (min-width:768px) and ( max-width:1024px){
      padding-right: 20px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 100px 0;
    }
    .title {
      width: 35%;
      border: 1px solid white;
      text-align: center;
      font-size: 25px;
      padding: 20px 0;
      @media screen and (max-width: 767px) {
        width: 80%;
      }
    }
    .text {
      width: 40%;
      @media screen and (max-width: 767px) {
        width: 80%;
        margin-top: 30px;
      }
    }
    a {
      width: 13%;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin-top: 30px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }
}
</style>
