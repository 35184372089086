<template>
  <div class="home-contact">
    <div class="blue-title">
      <div class="title-cover">
        <div class="title">
          <h2>お問い合わせ・資料請求</h2>
          <img
            alt="スマホ"
            src="@/assets/bioletry-top-pa005.png"
            class="imge"
          />
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="white-title"><p>製品の資料請求ができます</p></div>
      <div class="guide">
        <p style="font-size: 18px">
          資料にて、バイオレタリー製品のより詳しい情報をお届けしています。資料はお気軽にご請求ください。
        </p>
      </div>
      <!-- todo:電話廃止対応 -->
      <!-- <div class="white-title"><p>お電話でのお問い合わせ</p></div>
      <div class="guide">
        <p class="small">＼&nbsp;担当者と直接お話しできます&nbsp;／</p>
        <a href="tel:09032863513"><p class="tel">090-8160-5213</p></a>
        <p class="small">（受付時間:平日9時～18時）</p>
      </div>
      <div class="white-title"><p>メールでのお問い合わせ</p></div> -->
    </div>
    <div class="wrap sp-cover">
      <HomeContactContactForm
        class="space-btm-200"
        style="background-color: #f2f2f2"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";

export default {
  name: "HomeAboutUs",
  components: {
    // UiHeading,
    HomeContactContactForm,
  },
  props: {},
  data() {
    return {
      pageType: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-contact {
  .blue-title {
    background-color: var(--sub);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    margin-bottom: 100px;
    @media screen and (max-width: 767px) {
      margin-bottom: 50px;
    }
    .title-cover {
      .title {
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          // flex-direction: column-reverse;
          // padding-bottom: 125px;
        }
        h2 {
          color: white;
          font-size: 36px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
          }
        }
        .imge {
          width: 100px;
          margin-left: 15px;
          @media screen and (max-width: 767px) {
            width: 80px;
          }
        }
      }
    }
  }
  .white-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border: 2px solid var(--sub);
    margin-bottom: 50px;
    @media screen and (max-width: 767px) {
      padding: 20px 0;
    }
    p {
      font-size: 32px;
      color: var(--sub);
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
  }

  .guide {
    text-align: center;
    color: var(--main);
    margin-top: 20px;
    margin-bottom: 80px;
    @media screen and (max-width: 767px) {
      margin-bottom: 50px;
    }
    .head {
      @media screen and (max-width: 767px) {
        padding-bottom: 30px;
      }
    }

    a {
      text-decoration: none;
      color: var(--main) !important;
    }
    .tel {
      font-size: 60px;
      font-weight: bold;
      margin-top: 30px;
      color: var(--sub) !important;
      @media screen and (max-width: 767px) {
        font-size: 45px;
      }
    }
    .small {
      font-size: 22px;
      margin-top: 20px;
    }

    @media (min-width: 751px) {
      a[href*="tel:"] {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  .bottom {
    height: 200px;
    padding-top: 100px;
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }
    .container {
      width: 100%;
      img {
        vertical-align: bottom;
        width: 100%;
      }
    }
  }
}
.sp-cover {
  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
