<template>
  <div class="sample-qa-qestion">

    <div>
      <div class="outer">
        <div class="bar-box">
            <div class="bar-no mincyo">
              <p>Q</p>
            </div>
          <div class="cover  mincyo">       
              <p v-html="qestion" class="bar-text"></p>
          <div class="open" v-if="openContent === false">
          <svg
            id="レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 82 82"
            width="40px"
            height="40px"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M764,510v82h82V510Zm41,56.17-18.19-22.33,3.1-2.53L805,559.83l15.09-18.52,3.1,2.53Z"
              transform="translate(-764 -510)"
            />
          </svg>
          </div>
          <div class="open close" v-else-if="openContent === true">          
          <svg
            id="レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 82 82"
            width="40px"
            height="40px"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M764,510v82h82V510Zm41,56.17-18.19-22.33,3.1-2.53L805,559.83l15.09-18.52,3.1,2.53Z"
              transform="translate(-764 -510)"
            />
          </svg>
          </div>
</div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SampleQaQestion",
  components: {},
  props: {
    qestion: String,

    openContent: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cls-1,
.st0 {
  fill: var(--sub);
}
.outer {
  margin: 20px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    margin: 15px 0;
  }

  .bar-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80px;
    width: 100%;
    padding: 0 30px;
    border: 1px solid gray;
    @media screen and (max-width: 767px) {
      align-items: center;
      height:auto;
      min-height: auto;
      padding:10px;
    }
    .cover {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      width: 95%;
      @media screen and (max-width: 767px){
              align-items: center;
              height: auto;
      }
    }
    .bar-no {
      min-width: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-right: 1px solid gray;
      @media screen and (max-width: 767px){
        align-items: center;
        border-right: none;
        min-width: 20px;
        
      }

      p {
        font-size: 21px;
        color: var(--sub);
        @media screen and (max-width: 767px){
          // margin-top: 5px;
        }
      }
    }
    .bar-text {
      font-size: 18px;
      padding-left: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      width: 88%;
      color: var(--sub);
      // font-family: var(--fontfamilySub);
      //2行になりそうなタイトルにつける
      ::v-deep span {
        font-size: 21px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 5px;
      }

    }
  }
  .bar-box:hover {
  filter: opacity(85%);
  cursor: pointer;
}
}

.open {
  width: 40px;
  height: 40px;
  transition: .5s ;
			transform: rotateZ( 0deg ) ;
    @media screen and (max-width: 709px) {
      width: 38px;
      height: 38px;
      margin-left: 5px;
    }
}

.close{
transform: rotateZ( 180deg ) ;
}
.v-application p {
  font-family: "游明朝"!important;
}
</style>