<template>
  <div class="keitai-list space-top-bottom-set-200">
    <h3>バイオレタリーD20</h3>

    <div class="slider">
      <carousel
        :autoplay="true"
        :loop="true"
        :per-page="1"
        :navigation-enabled="true"
        :pagination-padding="0"
        :speed="1000"
        pagination-color="white"
        pagination-active-color="#0098d8"
      >
        <!-- <slide> <img :src="`${rootUrl}/img/slider-1.jpeg`" alt="" class="slide-img"/> </slide> -->
        <slide>
          <img
            src="@/assets/keitai/bioletry-keitai-006.jpg"
            alt=""
            class="slide-img"
          />
        </slide>
        <slide>
          <img
            src="@/assets/keitai/bioletry-keitai-007.jpg"
            alt=""
            class="slide-img"
          />
        </slide>
        <slide>
          <img
            src="@/assets/keitai/bioletry-keitai-014.jpg"
            alt=""
            class="slide-img"
          />
        </slide>


        <!-- <slide>
          <img
            src="@/assets/keitai/bioletry-keitai-010.jpg"
            alt=""
            class="slide-img"
          />
        </slide> -->
      </carousel>
    </div>
    <div class="wrap">
      <div class="item">
        <UiTable3 :tableList="tableList" class="table" />
        <UiTable3 :tableList="tableList2" class="table" />
      </div>
      <p class="price">定価：6,300円<span>&nbsp;(税込)&emsp;※送料別</span></p>
                  <p class="note">
        送料別途。詳細はお問い合わせくださいませ。<br>
        処理能力は目安です。季節・天候・使用条件などによって変わります。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import UiTable3 from "@/components/Ui/Table3.vue";
export default {
  name: "keitaiList",
  components: {
    UiTable3,
    Carousel,
    Slide,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "型式",
          td: "バイオレタリーD20",
        },
        {
          id: 2,
          th: "品名",
          td: "非常時用 減容型 ダンボール バイオトイレ",
        },
        {
          id: 3,
          th: "本体サイズ",
          td: "W25×D25×H20",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "重量",
          td: "1.5kg",
        },
        {
          id: 2,
          th: "処理能力",
          td: "4回",
        },
        {
          id: 3,
          th: "備考",
          td: "車中・お子様向けサイズ",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.keitai-list {
  h3 {
    text-align: center;
    color: white;
  }
  .temawashi {
    border: solid 1px white;
    margin-left: 10px;
    font-size: 18px;
    padding: 5px 10px;
    vertical-align: top;
  }
}
.slider {
  margin: 50px auto;
  width: 40%;
  @media screen and (max-width: 767px) {
    width: 80%;
  }
}
/deep/.VueCarousel-dot-container {
  width: 150px;
  display: flex !important;
  justify-content: space-between;
  padding-top: 20px !important;
  @media screen and (max-width: 767px) {
    width: 100px;
  }
}
.item {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .table {
    width: 47%;
    margin-top: 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 0;
    }
  }
}
.price {
  width: 100%;
  background-color: var(--sub2);
  color: white;
  font-size: 20px;
  padding: 10px 0;
  margin: 30px 0 0;
  text-align: center;
  span {
    font-size: 14px;
  }
}
.note {
  text-align: center;
  color: white;
  margin-top: 10px;
  @media screen and(max-width: 767px) {
    line-height: 1.8em;
  }
}
/deep/.VueCarousel-navigation-button{
  color: white;
}
</style>
