<template>
  <div class="home-merit">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'バイオトイレのメリット'"
      class="space-middle-bottom-img"
    />
    <div class="cover">
      <div class="item">
        <img alt="臭わない" src="@/assets/bioletry-top-012.png" />
        <p>
          バイオトイレは排泄物、トイレットペーパーを完全に分解します。そのため、従来の仮設・簡易・携帯トイレにあった「くさい」「臭いが残る」という問題がありません。快適な利用ができます。特に女性に喜ばれています。<br>※菌床はおがくずなどを使用しており、木や腐葉土のような匂いは多少あります。
        </p>
      </div>
      <div class="item">
        <img
          alt="汲み取り後処理清掃が不要"
          src="@/assets/bioletry-top-013.png"
        />
        <p>
          バイオトイレは排泄物を消します。そのため、汲み取り、後処理など一切不要です。このような作業に対応するストレスも、コスト負担からも開放されます。
        </p>
      </div>
      <div class="item">
        <img alt="水がなくても使える" src="@/assets/bioletry-top-014.png" />
        <p>
          バイオトイレは水がなくても使用できます。そのため、使用環境に排水溝や蛇口は必要ありません。水がない場所という条件下においても利用できるのがバイオトイレです。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "HomeHowToUse",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.home-merit {
  .cover {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and(max-width: 767px) {
      flex-direction: column;
    }
    .item {
      width: 32%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      p{
        white-space:pre-wrap
      }
      img{
        width: 100%;
        @media screen and (max-width: 767px) {
          width: 80%;
        }
      }
    }
    img {
      margin-bottom: 30px;
      @media screen and (max-width: 767px){

        margin-bottom: 0;
      }
    }
  }
}
</style>
