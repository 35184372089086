<template>
  <div class="flow">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'お問い合わせから<br class=sp-only>納入までの流れ'"
      class="space-middle-bottom-img"
    />
    <UiFlowBox
      v-for="flowBoxListData in flowBoxList"
      :key="flowBoxListData.id"
      :barNo="flowBoxListData.barNo"
      :barTitle="flowBoxListData.barTitle"
      :barText="flowBoxListData.barText"
      :sankaku="flowBoxListData.sankaku"
      class="item"
    />
        <img
      alt="バイオくん青色"
      src="@/assets/bioletry-top-ira006.png"
      class="blue"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiFlowBox from "../Ui/FlowBox.vue";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "about-recruit",
  components: {
    UiFlowBox,
    UiHeading,
  },
  data() {
    return {
      flowBoxList: [
        {
          id: 1,
          barNo: "1",
          // todo:電話廃止対応
          // barTitle: "お電話、または、メールでお問い合わせ",
          barTitle: "メールでお問い合わせ",
          // barText:
          //   "お電話（担当者直通：090-8160-5213）、または、お問い合わせフォームからメールでお問い合わせをお願いします。",
          barText:
            "当ホームページ内のお問い合わせフォームからメールでお問い合わせをお願いします。なるべくお値打ち価格で製品を提供するために、電話の窓口はご用意していません。",
          sankaku: true,
        },
        {
          id: 2,
          barNo: "2",
          barTitle: "打ち合わせの日程を調整",
          barText: "商品の説明などの打ち合わせの日程を調整します。",
          sankaku: true,
        },
        {
          id: 3,
          barNo: "3",
          barTitle: "打ち合わせ",
          barText:
            "打ち合わせにて、商品と料金の説明、納入のスケジュールの調整などをします。",
          sankaku: true,
        },
        {
          id: 4,
          barNo: "4",
          barTitle: "納品",
          barText: "商品を使用場所に納品します。",
          sankaku: true,
        },
        {
          id: 5,
          barNo: "5",
          barTitle: "使用とメンテナンス",
          barText:"バイオトイレを使用します。使用する商品ごとに、規定のメンテナンスを行います。",
          sankaku: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.flow{
  position: relative;
}
.item-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .left {
    width: 24%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      margin-top: 15px;
    }
    img {
      width: 80%;
      margin-top: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    h2 {
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 34px;
      }
    }
  }
  .right {
    width: 67%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 50px;
    }
  }
}
.space-top-bottom-set {
  @media screen and (max-width: 767px) {
    padding-bottom: 80px;
  }
}
.blue{
  position: absolute;
  width: 100px;
  bottom: -5%;
  right: 0%;
  @media screen and (max-width: 767px){
    width: 70px;
    bottom: -2%;
  }
}
</style>
