import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'
import Okugai from '../views/Okugai.vue'
import Katei from '../views/Katei.vue'
import Keitai from '../views/Keitai.vue'
import Company from '../views/Company.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'バイオトイレなら「バイオレタリー」※業界最安値に挑戦',
      description: 'これまでの仮設型トイレや簡易トイレとは全く異なり、便を分解させる働きがあるため、①臭わない、②汲み取り・後処理不要、③水がなくても使用できます。バイオトイレの技術をあらゆる用途に適用し、屋外設置型の仮設用、家庭用簡易型、携帯用折りたたみ式の3種類の製品カテゴリをご用意しています。バイオトイレの業界最安値を目指していますので、ぜひお気軽にご相談をください。'
    }
  },
  {
    path: '/okugai/',
    name: 'okugai',
    component: Okugai,
    meta: {
      title: '屋外設置型バイオトイレ「バイオレタリー」※業界最安値に挑戦',
      description: 'バイオレタリーは、建設現場・離島・キャンプ場・イベント会場などに使用する、屋外設置型のバイオトイレです。臭わない、汲み取り・後処理不要、水なしで使用できることはもちろん、独自の撹拌部分の構造によって、軽トラックで運搬・移動できるコンパクトサイズにすることに成功しました。'
    }
  },
  {
    path: '/katei/',
    name: 'katei',
    component: Katei,
    meta: {
      title: '家庭用簡易型バイオトイレ「バイオレタリー」※業界最安値に挑戦',
      description: '家庭用簡易型バイオトイレ「バイオレタリー」は、臭わない、後処理不要のバイオトイレをご家庭でも使用できます。使用に際して、水道蛇口も排水溝も不要です。従来の家庭用トイレにある、臭い、片付けから開放されます。介護用、障害者用、備蓄・防災、キャンピングカー、キャンプ場など様々な用途で使用されています。'
    }
  },
  {
    path: '/keitai/',
    name: 'keitai',
    component: Keitai,
    meta: {
      title: '携帯型折りたたみ式バイオトイレ「バイオレタリー」※業界最安値に挑戦',
      description: '携帯型折りたたみ式バイオトイレ「バイオレタリー」は、ダンボールによる折りたたみ式で持ち運びができるバイオトイレです。従来の携帯用トイレとは、仕組みが全く異なり、臭わず、便を減容します。すぐに準備でき、秒速で臭いが消えます。そのため、車内、トラック、キャンプや登山や釣りなどのアウトドアなど、さまざまな用途で使用できます。'
    }
  },
  {
    path: '/company/',
    name: 'company',
    component: Company,
    meta: {
      title: '販売会社情報・製造会社情報｜バイオレタリー',
      description: 'バイオトイレ「バイオレタリー」の販売会社情報・製造会社情報です。販売会社：株式会社バイオシステム、製造会社：株式会社TKエコシステムズ。'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");
  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
  // next();
  });

export default router
