<template>
  <div class="okugai-explanation">
    <div class="item-cover">
      <img
        alt="自然イメージ"
        src="@/assets/okugai/bioletry-kyotu-ira001.png"
        class="number"
      />
      <div class="text">
        <h3>珍しい、大小分離で一体型</h3>
        <p>
          大便と小便を分離した構造で、一体型の仕様にしました。使いやすく、また、使用者の用途に分けて使用できます。
        </p>
      </div>
      <img
        alt="分離型"
        src="@/assets/okugai/bioletry-okugai-004.png"
        class="zu"
      />
    </div>
    <div class="item-more-cover">
      <div class="item-cover3">
        <img
          alt="01"
          src="@/assets/okugai/bioletry-kyotu-ira002.png"
          class="number"
        />
        <div class="text">
          <h3>軽トラックで運搬できる<br class="sp-only" />コンパクトな設計</h3>
          <p>
            タライ状の攪拌槽に大小の便器を設け、省スペース化することで、コンパクトな設計になっています。法定で定められた軽トラックの積載寸法以内に収まるサイズにし、移動・運搬をラクにしました。制限外積載許可申請を取得する必要がありません。ソーラー発電システム搭載型は、２ｍを超えますので軽以外のトラックで搬送してください。
          </p>
        </div>
      </div>
      <div class="img-cover">
        <img
          alt="01"
          src="@/assets/okugai/bioletry-okugai-011.jpg"
          class="truck-p"
        />
        <img
          alt="01"
          src="@/assets/okugai/bioletry-okugai-012.jpg"
          class="truck-zu"
        />
      </div>
    </div>
    <div class="item-more-cover">
      <div class="item-cover3">
        <img
          alt="03"
          src="@/assets/okugai/bioletry-kyotu-ira003.png"
          class="number"
        />
        <div class="text">
          <h3>省エネ</h3>
          <p>
            撹拌部は外周を廻すことで大トルクを発生させ、小さなモーターで稼働させることで省エネを実現しました。
          </p>
        </div>
      </div>
      <div class="flex">
      <div class="blue-back">
        <p class="title">太陽光の利用について</p>
        <p class="text">
          太陽光による発電での使用は、使用場所や使用状況によって、太陽光のみですべての電力を賄える場合とそうでない場合があります。詳細はお問い合わせくださいませ。
        </p>
      </div>
       <img
          alt="03"
          src="@/assets/okugai/bioletry-okugai-018.jpg"
          class="number"
        />
      </div>
    </div>
    <div class="item-cover2">
      <img
        alt="03"
        src="@/assets/okugai/bioletry-kyotu-ira004.png"
        class="number"
      />
      <div class="text">
        <h3>ヒーター付き</h3>
        <p>
          ヒーターによって、菌が活発に動く15度～35度の気温にする仕様のため、冬場でも処理が早いです。但し、環境により使用ヒーター容量が異なります。
        </p>
      </div>
    </div>

    <!-- <div class="link-button" style="pointer-events: none">
      <router-link to="/">屋外設置型バイオトイレの詳細を見る</router-link>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "OkugaiExplanation",
  components: {
    // UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.okugai-explanation {
  .item-cover,
  .item-cover2,
  .item-cover3 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .number {
      width: 25%;
      margin-right: 20px;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
      }
    }
    h3 {
      color: var(--sub);
      @media screen and(max-width: 767px) {
        text-align: center;
      }
    }
  }
  .item-cover {
    padding: 100px 0 30px;
    @media screen and (max-width: 767px) {
      padding-bottom: 60px;
      padding-top: 60px;
    }
    .text {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .zu {
      width: 48%;
      margin-right: -130px;
      margin-bottom: -200px;
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        margin-right: 0;
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        margin: 0;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .item-cover2 {
    border-top: 1px var(--main) solid;
    padding: 100px 0;
    @media screen and(max-width: 767px) {
      padding: 60px 0 100px;
    }
    .text {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .item-cover3 {
    padding: 100px 0 0;
    border-top: 1px var(--main) solid;
    @media screen and(max-width: 767px) {
      padding: 60px 0 30px;
    }
  }
  .img-cover {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: calc(28% - 20px);
    padding-top: 30px;
    padding-bottom: 100px;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      flex-direction: column;
      padding-bottom: 60px;
      padding-top: 0px;
    }
    .truck-p {
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .truck-zu {
      width: 43%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .blue-back {
    margin-left: 28%;
    background-color: #e5f5fb;
    padding: 20px;
    margin-top: -30px;
    margin-bottom: 100px;
    @media screen and (min-width:768px) and ( max-width:1024px){
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      margin: 0 0 60px;
    }
    .title {
      color: var(--sub);
      font-size: 20px;
    }
  }
}
.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and(max-width: 767px){
    flex-direction: column;
  }
  img{
    width: 180px;
    margin-top: -100px;
    margin-left: 20px;
    @media screen and(max-width: 767px){
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 60px;
    }
  }
}
</style>
