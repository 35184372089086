<template>
  <div class="home-to-use">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'こんなシーンでバイオレタリーが<br class=tb-only>使用されています'"
      class="space-middle-bottom-img"
    />
    <div class="item-cover">
      <p class="blue">建設現場</p>
      <p class="blue">介護用</p>
      <p class="blue2">車内</p>
      <p class="blue2">登山</p>
      <p class="blue3">離島</p>
      <p class="blue3">障害者用</p>
      <p>トラック</p>
      <p>釣り</p>
      <p class="blue">イベント</p>
      <p class="blue">備蓄・防災</p>
      <p class="blue2">キャンピングカー</p>
      <p class="blue2">農業</p>
      <p class="blue3">キャンプ場</p>
      <p class="blue3">被災地</p>
    </div>
    <p class="note">
      ※トイレがない環境なら<br class="sp-only" />便利に使用できます。
    </p>
    <img
      alt="バイオくん黄色"
      src="@/assets/bioletry-top-ira003.png"
      class="yel"
    />
    <img
      alt="バイオくん赤色"
      src="@/assets/bioletry-top-ira004.png"
      class="red"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "HomeHowToUse",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-to-use {
  position: relative;
}
.note {
  position: absolute;
  bottom: 10%;
  right: 0;
  @media screen and (max-width: 767px) {
    bottom: -8%;
    right: 0;
    line-height: 1.8em;
  }
}
.yel,
.red {
  width: 100px;
  @media screen and (max-width: 767px) {
    width: 70px;
  }
}
.yel {
  position: absolute;
  top: 13.5%;
  right: 3%;
@media screen and (min-width:768px) and ( max-width:1024px){
  top: 20%;
}
  @media screen and (max-width: 767px) {
    top: 11.2%;
  }
}
.red {
  position: absolute;
  bottom: -2%;
  right: 41%;
  @media screen and (max-width: 767px) {
    bottom: -5%;
    right: 80%;
  }
}
.item-cover {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  p {
    border-radius: 15px;
    width: 24%;
    color: white;
    text-align: center;
    padding: 20px 0;
    background-color: var(--sub2);
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      width: 48.5%;
      padding: 20px 0;
      margin-bottom: 10px;
    }
  }
  .blue,
  .blue2 {
    background-color: var(--sub);
  }
  .blue2 {
    @media screen and (max-width: 767px) {
      background-color: var(--sub2);
    }
  }
  .blue3 {
    background-color: var(--sub2);
    @media screen and (max-width: 767px) {
      background-color: var(--sub);
    }
  }
}
.item-cover:after {
  content: "";
  width: 24%;
}
.item-cover:before {
  content: "";
  width: 24%;
  order: 1;
}
</style>
