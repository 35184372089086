<template>
  <div class="katei-etc">
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'業界最安値に挑戦しています'"
      class="space-middle-bottom-img"
    />
    <p class="space-bottom-200">
     バイオレタリーは業界最安値に挑戦していますので、すでに取得済の他社の見積書があれば、同スペックならばその価格よりも安く製品を提供できるように努力させていただきます。お気軽にお問い合わせくださいませ。ボリュームディスカウントもご相談を承ります。
    </p>
    <UiHeading
      :h="'3'"
      :type="'E'"
      :text="'メンテナンス・<br class=sp-only>消耗品について'"
      class="space-middle-bottom-img"
    />
    <div class="center">
    <p>
      携帯型折りたたみ式バイオトイレは、菌床は消耗品のため、予備菌床セットを購入していただければ、何度でも使用ができます。
    </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "KateiEtc",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.katei-top {
  .cover {
    background-color: var(--sub);
    padding-left: calc(calc(100vw - 1100px) * 0.5);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h1 {
      padding: 50px 0;
      color: white;
    }
    img {
      width: 30%;
      padding-right: 100px;
    }
  }
  .middle-cover {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -150px;
    margin-bottom: 50px;
    p {
      width: 70%;
      // margin-top: 100px;
    }
    .right {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      // margin-right: 100px;
      .bio {
        width: 45%;
        height: auto;
      }
      .tokkyo {
        width: 100px;
        margin-bottom: 30px;
        margin-right: 60px;
      }
    }
  }
  .img-cover {
    display: flex;
    justify-content: space-between;
    img {
      width: 31%;
    }
  }
}
  .center{
   display: flex;
   justify-content: center;
  }
</style>
