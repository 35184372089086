<template>
  <div class="company-top">
    <div class="cover">
      <div class="top wrap">
        <h1 class="pc-only">会社情報</h1>
        <img
          alt="街イラスト"
          src="@/assets/bioletry-kaisya-001.png"
          class="pc-only"
        />
        <img
          alt="街イラスト"
          src="@/assets/bioletry-kaisya-sp001.jpg"
          class="sp-only"
        />
      </div>
    </div>
    <div class="info wrap">
      <div class="table">
        <p>販売会社</p>
        <UiTable4 :tableList="tableList" />
      </div>
      <div class="table">
        <p>製造会社</p>
        <UiTable4 :tableList="tableList2" />
      </div>
    </div>
    <div class="kin-cover">
    <img alt="菌くん" src="@/assets/bioletry-okugai-07.jpg" class="kinkun" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiTable4 from "@/components/Ui/Table4.vue";
export default {
  name: "kateiTop",
  components: {
    UiTable4,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList: [
        {
          id: 1,
          th: "企業名",
          td: "株式会社バイオサービス",
        },
        {
          id: 2,
          th: "代表者名",
          td: "伊賀 忠",
        },
        {
          id: 3,
          th: "本社所在地",
          td: "526-0803 滋賀県長浜市西上坂町355番地",
        },
        {
          id: 4,
          th: "電話番号",
          td: "090-8160-5213",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "企業名",
          td: "TKエコシステムズ株式会社",
        },
        {
          id: 2,
          th: "代表者名",
          td: "髙橋 辰夫",
        },
        {
          id: 3,
          th: "工場所在地",
          td: "503-0986 岐阜県大垣市中曽根町698番地1",
        },
        {
          id: 4,
          th: "電話番号",
          td: "0584-92-1517",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.company-top {
  padding-top: 50px;
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
  .cover {
    background-color: var(--sub);
    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h1 {
        padding: 50px 0;
        color: white;
      }
      img {
        width: 25%;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding-right: 0;
        }
      }
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    @media screen and(max-width: 767px) {
      flex-direction: column;
    }
    .table {
      width: 47%;
      @media screen and(max-width: 767px) {
        width: 100%;
        margin-bottom: 50px;
      }
      p {
        font-size: 28px;
        margin-bottom: 20px;
        @media screen and(max-width: 767px) {
          text-align: center;
          font-size: 24px;
        }
      }
    }
  }
}
.kin-cover{
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-left: 20px;
  }
  .kinkun {
    width: 200px;
    margin-right: 200px;
    margin-left: calc(100% - 400px);
    margin-top: 50px;
    @media screen and (min-width:768px) and ( max-width:1024px){
      margin-right: 0;
      margin-left: calc(100% - 220px);
    }
    @media screen and (max-width: 767px) {
      margin: 0;
      // margin-left: calc(100%- 200px);
      // margin-right: 0;
    }
  }
}
/deep/th {
  color: var(--sub);
}
</style>
