<template>
  <div class="top">
    <div class="cover">
      <div class="filter">
        <video ref="player" autoplay muted playsinline>
          <source src="/media/bioretary.top.mp4" />
          <!-- <source src="@/assets/header1280-720px.mp4" /> -->
          このブラウザではサポートされていません。
        </video>
      </div>

      <!-- <div class="container" :class="{
        'animation-start':
          (path !== '/' && scrollY >= 0) || (path === '/' && scrollY >= 800),
      }" style="font-family: '游明朝','游明朝体'"> -->
      <div ref="container" class="container animation-start">
        <div class="top-img-cover">
          <img
            alt="バイオレタリートップ"
            src="@/assets/bioletry-top-main001.png"
            class="logo pc-only"
          />
          <img
            alt="バイオレタリートップ"
            src="@/assets/bioletry-top-main001.png"
            class="logo sp-only"
          />
          <div class="maru-cover">
            <img
              alt="バイオレタリートップ2"
              src="@/assets/bioletry-top-main-p001.png"
              class="kurukuru4"
            />
            <img
              alt="バイオレタリートップ3"
              src="@/assets/bioletry-top-main-p002.png"
              class="kurukuru"
            />
            <img
              alt="バイオレタリートップ4"
              src="@/assets/bioletry-top-main-p003.png"
              class="kurukuru2"
            />
            <img
              alt="バイオレタリートップ5"
              src="@/assets/bioletry-top-main-p004.png"
              class="kurukuru3"
            />
          </div>
          <img
            alt="バイオレタリートップ6"
            src="@/assets/bioletry-top-main002.webp"
            class="check pc-only"
          />
        </div>
      </div>
    </div>
    <div class="sp-check-cover">
      <img
        alt="バイオレタリートップ6"
        src="@/assets/bioletry-top-main002sp.webp"
        class="check "
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  mounted: function () {
    let player = this.$refs.player;
    let container = this.$refs.container;

    player.addEventListener("timeupdate", function () {
      if (player.currentTime > 23) {
        container.style.display = "none";
        container.classList.remove("animation-start");
      }
    });

    player.addEventListener("ended", function () {
      console.log("ended");
      container.style.display = "";
      container.classList.add("animation-start");
      player.play();
    });
  },
};
</script>

<style lang="scss" scoped>
.cover {
  // background-image: url(~@/assets/top-chira.png);
  // background-color: rgb(183, 190, 216); //動画に黒のフィルターかける
  background-color: #35a7db; //動画に黒のフィルターかける
  display: inline-block; //上記とセット
  position: relative;
  //ここからスマホ全画面用
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: -70px;
  vertical-align: bottom;
  //   @media screen and (max-width: 820px){
  // padding-bottom: 200px;
  //   }

  .filter {
    opacity: 0.8;
    display: block;
    // background-image: url(~@/assets/top-chira.png);

    //ここからスマホ全画面用
    position: relative !important;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: no-repeat center center/cover;
  }

  video {
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
  }

  /*動画よりも画面が縦に長くなるとき用*/
  @media (max-aspect-ratio: 16/9) {
    video {
      height: 100%;
      left: 60%;
      transform: translateX(-60%);
    }
  }

  .animation-start {
    animation: fadeout-anim2 20s forwards;
    animation-timing-function:cubic-bezier(0.95, 0.05, 0.795, 0.035);
    margin-right:0;
  }

  .container {
    position: absolute;
    top: 30%;
    left: 28%;
    // letter-spacing: 0.3em;
    z-index: 100;
    color: white;
    margin: 0;
    @media screen and (min-width:768px) and ( max-width:1024px){
      top: 30%;
      left: 25%;
    }
    @media screen and (max-width: 767px) {
      top: 20%;
      left: 0;
    }
    .top-img-cover {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      .logo {
        margin-bottom: 20px;
        width: 70%;
        @media screen and (max-width: 820px) {
          width: 80%;
          margin-bottom: 1px;
        }
      }
      .maru-cover {
        width: 70%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
        @media screen and (max-width: 767px) {
          width: 70%;
          flex-wrap: wrap;
          margin-top: 20px;
        }
        img {
          width: 23%;
          @media screen and (max-width: 767px) {
            width: 48%;
          }
        }
      }
      .check {
        @media screen and (max-width: 767px) {
          width: 80%;
          // display: none;
        }
      }
    }

    // h1 {
    //   color: var(--main);
    //   font-size: 38px;
    //   margin-top: 40px;
    //   @media screen and (max-width: 820px) {
    //     font-size: 26px;
    //     letter-spacing: 0.05em;
    //     line-height: 1.3em;
    //   }
    // }
    // p {
    //   color: var(--main);
    //   font-size: 22px;
    //   font-weight: bold;
    //   letter-spacing: 0.05em;
    //   margin-top: 20px;
    //   @media screen and (max-width: 820px) {
    //     font-size: 18px;
    //     margin-top: 40px;
    //   }
    // }
    // .catch {
    //   font-size: 56px;
    //   line-height: 1.3em;
    //   letter-spacing: 0.22em;
    //   @media screen and (max-width: 767px) {
    //     font-size: 26px;
    //     letter-spacing: 0.15em;
    //   }
    // }

    @keyframes fadeout-anim {
      100% {
        opacity: 0;
      }
    }
    @keyframes fadeout-anim2 {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes rotate-anime {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.sp-check-cover {
  display: none;
  @media screen and (max-width: 820px) {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
  .check {
    @media screen and (max-width: 820px){
      display: initial;
      margin: 20px 0;
      width: 80%;
    }
  }
}

@keyframes rotate-y{
  0%{transform:rotateY(0deg);}
  100%{transform:rotateY(360deg);}
}
@-webkit-keyframes rotate-x{
  0%{-webkit-transform:rotateY(0deg);}
  100%{-webkit-transform:rotateY(360deg);}
}
.kurukuru{
  animation:rotate-y 2s 1s 2;
  -webkit-animation:rotate-y 2s 1s 2;
}
// .kurukuru3{
//   animation:rotate-y 2s 8s 2;
//   -webkit-animation:rotate-y 2s 8s 2;
// }
.kurukuru3{
  animation:rotate-y 2s 13s 2;
  -webkit-animation:rotate-y 2s 13s 2;
}
// .kurukuru2{
//   animation:rotate-y 2s 21s 2;
//   -webkit-animation:rotate-y 2s 21s 2;
// }
</style>
