<template>
  <div class="keitai-explanation">
    <div class="item-cover pt-50">
      <img
        alt="自然イメージ"
        src="@/assets/okugai/bioletry-kyotu-ira001.png"
        class="number"
      />
      <div class="text">
        <h3>希少な携帯型折りたたみ式<br />バイオトイレ</h3>
        <p>
          携帯型折りたたみ式のバイオトイレは世の中で多く製造されていなくて希少です。バイオトイレは、従来のトイレのデメリットがありません。排泄物が減容する。においが消滅する。使い捨てではなく、小4回～8回、大2回までなら、汚物袋セットを交換するだけで、何度でも使用できます。このようなメリットがあります。
        </p>
      </div>
      <img
        alt="携帯型折りたたみ式バイオトイレ"
        src="@/assets/keitai/bioletry-keitai-008.jpg"
        class="zu"
      />
    </div>
    <div class="item-cover border">
      <img
        alt="01"
        src="@/assets/okugai/bioletry-kyotu-ira002.png"
        class="number"
      />
      <div class="text2">
        <h3>使用後にバイオ菌（バクテリア・微生物）を振りかけることで<br />秒速で臭いが消える</h3>
        <p>
          使用後にバイオ菌（バクテリア・微生物）を振りかけることで秒速で臭いが消えます。くささが残らないので使用しやすいです。
        </p>
      </div>

      <img
        alt="菌床"
        src="@/assets/keitai/bioletry-keitai-009.jpg"
        class="kinsyo"
      />
    </div>

    <div class="item-cover2">
      <img
        alt="03"
        src="@/assets/okugai/bioletry-kyotu-ira003.png"
        class="number"
      />
      <div class="text">
        <h3>使い捨てではなく、<br class="sp-only">何度でも使用できる</h3>
        <p>
          使用後投入菌床を振りかけることで、何度でも使用できます。使い捨てではありません。※ダンボール本体は、予備菌床セットを購入していただければ何度でも使用できます。
        </p>
      </div>
    </div>
    <div class="item-cover2">
      <img
        alt="04"
        src="@/assets/okugai/bioletry-kyotu-ira004.png"
        class="number"
      />
      <div class="text">
        <h3>折りたたみ式でコンパクト</h3>
        <p>
          ダンボールの折りたたみ式のため、保管サイズがコンパクトです。そのため、災害時の備蓄など保管性も良い。
        </p>
      </div>
    </div>
    <div class="item-cover2 sp-b">
      <img
        alt="04"
        src="@/assets/okugai/bioletry-kyotu-ira005.png"
        class="number"
      />
      <div class="text">
        <h3>菌床を肥料とし<br class="sp-only">再利用できる</h3>
        <p>
          バオイレタリーの菌床は、米ぬかとおがくずでできているため、肥料として再利用することができます。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
export default {
  name: "KeitaiExplanation",
  components: {
    // UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.keitai-explanation {
  .item-cover,
  .item-cover2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 60px 0;
    }
    .number {
      width: 25%;
      @media screen and (max-width: 767px) {
        width: 50%;
        margin: 0 auto;
      }
    }
    h3 {
      color: var(--sub);
      @media screen and(max-width: 767px){
        width: 100%;
        text-align: center;
      }
    }
  }
  .item-cover {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .text,.text2 {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .text{
      width: 37%;
      @media screen and(max-width: 767px){
        width: 100%;
      }
    }
    .text2{
      width: 50%;
      @media screen and(max-width: 767px){
        width: 100%;
      }
    }
    .zu{
      width: 33%;
      @media screen and(max-width: 767px){
        width: 100%;
        margin-top: 20px;
      }
    }
    .kinsyo{
      width: 15%;
      margin-left: 5%;
      @media screen and(max-width: 767px){
        width: 70%;
        margin-left: 0;
        margin: 0 auto;
         margin-top: 20px;
      }
    }
  }
  // &:nth-last-child(2){
  //  border-top: 1px var(--main) solid;
  // }
  .item-cover2 {
    border-top: 1px var(--main) solid;
    .text {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
.border {
  border-top: 1px var(--main) solid;
}
.pt-50{
  padding-top: 50px;
  @media screen and (max-width: 767px){
    padding-top: 20px!important;
  }
}.sp-b{
  @media screen and (max-width: 767px){
    padding-bottom: 100px!important;
  }
}
</style>
