<template>
  <div id="app">
    <Header class="header" :path="path" />
    <router-view class="body-top" />
    <ToTopButton :path="path" />
    <Footer :path="path" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {
      // path: String,
    };
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");
#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: #3e3a39;
  line-height: 2.5em;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

:root {
  --main: #3e3a39;
  --sub: #003f96;
  --sub2: #0098d8;
  --black: #3e3a39;
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}
.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;
  line-height: 2.2em;

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 70px !important;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.tb-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: block !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: block!important;
  }
}

h1,
h2 {
  font-size: 37px;
  line-height: 1.5em;
  // font-family: "YuMincho", /* Mac用 */ "Yu Mincho", /* Windows用 */ serif;
  // font-weight: bold;
  @media screen and (max-width: 767px) {
    font-size: 26px;
  }
}
h3 {
  font-size: 30px;
  line-height: 1.5em;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
  font-size: 26px;
  }
}
ul{
  padding-left: 20px;
	list-style-type: disc;
	list-style-position: outside;
}
.link-button {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  a {
    border: 1px solid var(--sub);
    padding: 10px 30px;
    text-decoration: none;
    color: var(--sub);
    &:hover {
      cursor: pointer;
      background-color: var(--sub);
      color: white;
      transition: 1s;
    }
  }
}

//共通スペースレンジャー
.space-top-bottom-set {
  padding-top: 150px;
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.space-top-bottom-set-200 {
  padding-top: 200px;
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.space-top-bottom-set-250 {
  padding-top: 250px;
  padding-bottom: 250px;
  @media screen and (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
.space-top-bottom-set-250-200 {
  padding-top: 250px;
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
.space-top-bottom-set-200-250 {
  padding-top: 200px;
  padding-bottom: 250px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}
//ヘッダー用のスペースレンジャー
.space-top-80 {
  padding-top: 80px;
  @media screen and (max-width: 767px) {
    padding-top: 20px;
  }
}
.space-top-70 {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-bottom {
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    padding-bottom: 80px;
  }
}
.space-bottom-200 {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.space-bottom-200m {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-top {
  padding-top: 150px;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}
.space-top-200 {
  padding-top: 200px;
  @media screen and (max-width: 767px) {
    padding-top: 100px;
  }
}

.space-middle-bottom-text {
  padding-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
}
.space-middle-bottom-img {
  padding-bottom: 70px;
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
}
</style>
