<template>
  <div class="keitai-top">
    <div class="cover">
      <h1 class="pc-only">
        バイオレタリー<br />携帯型折りたたみ式ダンボールバイオトイレ
      </h1>
      <img
        alt="自然イラスト"
        src="@/assets/keitai/bioletry-keitai-001.png"
        class="pc-only"
      />
      <img
        alt="自然イラスト"
        src="@/assets/keitai/bioletry-keitai-sp001.jpg"
        class="sp-only"
      />
    </div>
    <div class="wrap">
      <div class="middle-cover">
        <p>
          ダンボールによる折りたたみ式で持ち運びができるバイオトイレです。従来の携帯用トイレとは、仕組みが全く異なり、臭わず、便が減容します。すぐに準備でき、秒速で臭いが消えます。そのため、車内、トラック、キャンプや登山や釣りなどのアウトドア等など、さまざまな用途に使用されています。
        </p>
        <div class="right">
          <img
            alt="携帯型折りたたみ式バイオ"
            src="@/assets/keitai/bioletry-keitai-002.png"
            class="bio"
          />
          <!-- <img
            alt="実用新案出願中"
            src="@/assets/keitai/bioletry-keitai-003.png"
            class="tokkyo"
          /> -->
        </div>
      </div>
      <div class="img-cover">
        <img
          alt="使用イメージ1"
          src="@/assets/keitai/bioletry-keitai-001.jpg"
          class="big"
        />
        <img
          alt="使用イメージ2"
          src="@/assets/keitai/bioletry-keitai-002.jpg"
        />
        <img
          alt="使用イメージ3"
          src="@/assets/keitai/bioletry-keitai-003.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "keitaiTop",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.keitai-top {
  padding-top: 50px;
  @media screen and (max-width: 767px) {
    padding-top: 0;
  }
  .cover {
    background-color: var(--sub);
    padding-left: calc(calc(100vw - 1100px) * 0.5);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding-right: 20px;
      padding-left: 20px;
    }
    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    h1 {
      padding: 50px 0;
      color: white;
    }
    img {
      width: 32%;
      padding-right: 3%;
        @media screen and (min-width:768px) and ( max-width:1024px){
  padding-right: 0;
  }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
  .middle-cover {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -150px;
    margin-bottom: 50px;
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    p {
      width: 70%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      // margin-top: 100px;
    }
    .right {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      @media screen and (max-width: 767px) {
        align-items: flex-start;
        justify-content: center;
        margin: 30px 0;
      }
      // margin-right: 100px;
      .bio {
        width: 55%;
        height: auto;
        @media screen and (max-width: 767px) {
          width: 55%;
        }
      }
      .tokkyo {
        width: 100px;
        margin-bottom: 30px;
        margin-right: 60px;
        @media screen and (max-width: 767px) {
          margin: 0 0 0 30px;
          width: 80px;
        }
      }
    }
  }
  .img-cover {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      // flex-direction: column;
      flex-wrap: wrap;
    }
    img {
      width: 31%;
      @media screen and (max-width: 767px) {
        width: 48%;
        margin-bottom: 10px;
      }
    }
    .big {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>
