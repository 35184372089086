<template>
  <div class="ui-flow-box">
    <div class="outer">
      <div class="bar-box">
        <div class="sp-cover">
          <div class="bar-no">
            <p>{{ barNo }}</p>
          </div>
          <div class="bar-title">
            <div class="title-cover">
              <p v-html="barTitle"></p>
            </div>
          </div>
        </div>
        <hr class="border" />
        <div class="bar-text">
          <p v-html="barText"></p>
        </div>
      </div>
      <div class="sankaku-cover" v-show="sankaku === true">
        <div class="sankaku"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiFlowBox",
  components: {
    // UiDetailButton,
  },
  props: {
    barTitle: String,
    barText: String,
    barNo: String,
    sankaku: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  padding: 20px 0;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bar-box {
    display: flex;
    background-color: #e5f5fb;
    align-items: stretch;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .sp-cover {
      display: flex;
      @media screen and (max-width: 767px) {
        align-items: center;
        justify-content: center;
        padding-top: 20px;
      }
      .bar-no {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 767px) {
          width: 20%;
          justify-content: flex-start;
        }
        p {
          font-size: 30px;
          color: var(--sub);
          @media screen and (max-width: 767px) {
            margin-left: 40px;
            vertical-align: middle;
            // width: 100%;
          }
        }
      }
      .bar-title {
        color: var(--sub);
        font-size: 18px;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 70px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
        .title-cover {
          height: auto;
          min-height: 64px;
          width: 80%;
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            width: 90%;
          }
        }
      }
    }
    .bar-text {
      font-size: 16px;
      width: 75%;
      display: flex;
      align-items: center;
      
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      p {
        margin: 20px;
        padding: 0px 35px;
        border-left: 1px solid var(--sub);
        min-height: 70px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          border: none;
          padding: 0 20px 10px;
          margin-top: 10px;
        }
      }
    }
  }
  .sankaku-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .sankaku {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 25px 0 25px;
      border-color: var(--sub) transparent transparent transparent;
    }
  }
}
.border {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    width: 80%;
    background-color: var(--sub);
    height: 1px;
  }
}
</style>
